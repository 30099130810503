import { updateObject } from 'store/utility';
import * as types from '../actions/actionTypes';
import { INCOMMING } from 'constants/app';

const initialState = {
  tabState: INCOMMING,
  refreshTime: new Date(),
};

const setTabState = (state, action) => {
  return updateObject(state, {
    tabState: action.payload,
  });
};
const refreshEcgInboxTable = (state, action) => {
  return updateObject(state, {
    refreshTime: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SETTABSTATE:
      return setTabState(state, action);
    case types.SET_ECG_INBOX_TABLE_DATA:
      return refreshEcgInboxTable(state, action);
    default:
      return state;
  }
};

export default reducer;
