import { Typography, styled } from '@mui/material';
import React from 'react';

const Container = styled(Typography, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'variant',
  name: 'ChipContainer',
  slot: 'Root',
})(({ theme, color, variant }) => ({
  fontSize: 14,
  fontFamily: 'Work Sans',
  color: color,
}));

const CustomColumnText = ({ label, color, variant = 'body2' }) => {
  return (
    <Container color={color} variant={variant}>
      {label}
    </Container>
  );
};

export default CustomColumnText;
