import React from 'react'
import { Field, Form, Formik } from 'formik';
import { COMPONENT_USAGE } from '../constant/Constant';
import { CheckboxWithLabel, Select, Switch } from 'formik-material-ui';


const AudioReviewComponent = ({ values, usage }) => {
  // This gives Audio Checbox on Setting page
  return (
    <Field
      type="checkbox"
      component={CheckboxWithLabel}
      name="access"
      key={'isAudioReview'}
      value={'isAudioReview'}
      Label={{ label: 'Audio Reviews' }}
      disabled={
        !values.provider ||
        usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS
      }
    />
  )
}

export default AudioReviewComponent
