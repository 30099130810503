import * as types from './actionTypes';

export const setTabState = (tabNumber) => {
  return {
    type: types.SETTABSTATE,
    payload: tabNumber,
  };
};
export const refreshEcgInboxTable = () => {
  return {
    type: types.SET_ECG_INBOX_TABLE_DATA,
    payload: new Date(),
  };
};
