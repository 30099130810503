import React, { useEffect, useState } from 'react';
import { Strophe } from 'strophe.js';
import { getDayStringFromDay, parseName } from 'utilities/Utilities';
import styles from './chat.module.css';
import moment from 'moment';
const ListItem = (props) => {
  const { item, selected, unreadMsgCount, status, onClick, messagePoolObject } = props;
  const { name, chatUserId } = item;
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (status === Strophe.Status.CONNECTED && unreadMsgCount[chatUserId] > 0) {
      setCount(unreadMsgCount[chatUserId]);
    } else {
      setCount(0);
    }
  }, [unreadMsgCount, chatUserId]);
  const displayDateString = (date) => {
    let retVal = moment(date).format('MM/DD/YYYY');
    let curDate = moment(new Date());
    let dateToCheck = moment(date);
    let diff = curDate.diff(dateToCheck, 'days');
    switch (true) {
      case diff == 0:
        retVal = dateToCheck.format('hh:mm A');
        break;
      case diff == 1:
        retVal = 'Yesterday';
        break;
      case diff > 1 && diff < 7:
        retVal = getDayStringFromDay(dateToCheck.day());
        break;
    }
    return retVal;
  };

  return (
    <div className={`${styles.listRow} ${selected ? styles.selectedRow : ''}`} onClick={onClick}>
      <div className={styles.listRowItem}>
        <div
          style={{
            fontWeight: count ? 'bold' : 'unset',
            fontSize: '14px',
            fontFamily: 'Work Sans',
            height: '100%',
          }}
        >
          {parseName(name.split(' ')[0], name.split(' ')[1]) || item.chatUserId}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div
            style={{
              fontWeight: count ? 'bold' : 'unset',
              fontSize: '10px',
              width: '30%',
              fontFamily: 'Work Sans',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              width: ' 100%',
              // textAlign: 'right',
            }}
          >
            {messagePoolObject[chatUserId]?.time
              ? displayDateString(new Date(messagePoolObject[chatUserId]?.time))
              : ''}
          </div>
          {count > 0 && !selected && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                width: ' 100%',
              }}
            >
              <div className={styles.count}>{count}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ListItem;
