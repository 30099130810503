import { Typography } from '@material-ui/core'
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain'
import React from 'react'
import { Grid } from '@mui/material';
import moment from 'moment';
import SkeletonLoader from 'components/Loading/SkeletonLoader';

const CoachThisSession = ({data}) => {
  return (
    <SectionMain
          headerElement={{
            headerText: `Session Details`,
          }}
          bodyElement={[
            {
              childElements: (
                <>
                  <Grid item lg={4} md={6} xs={12}>
                    <Typography variant="body2">Date</Typography>
                    <SkeletonLoader isloading={Object.keys(data).length === 0}>
                      <Typography variant="body1">
                        {moment(data?.patientAppointment?.scheduledStartTime).format('ll') || '--'}
                      </Typography>
                    </SkeletonLoader>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <Typography variant="body2">Time</Typography>
                    <SkeletonLoader isloading={Object.keys(data).length === 0}>
                      <Typography variant="body1">{`${moment(
                        data?.patientAppointment?.scheduledStartTime,
                      ).format('LT')} -
                        ${moment(data?.patientAppointment?.scheduledEndTime).format(
                        'LT',
                      )} (${moment(data?.patientAppointment?.scheduledEndTime).diff(
                        moment(data?.patientAppointment?.scheduledStartTime),
                        'minutes',
                      )} mins)`}</Typography>
                    </SkeletonLoader>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12} /*className={classes.callTypeSection}*/>
                    {/* {data &&
                      (data?.patientAppointment?.appointmentChannelTypeId === 'Video Call' ? (
                        <VideocamIcon />
                      ) : (
                        <PhoneIcon />
                      ))} */}

                    {/* <div className={classes.distanceFromIcon}> */}
                    <Typography variant="body2">Method of Session</Typography>
                    <SkeletonLoader isloading={Object.keys(data).length === 0}>
                      <Typography variant="body1">
                        {data?.patientAppointment?.appointmentChannelType}
                      </Typography>
                    </SkeletonLoader>
                    {/* </div> */}
                  </Grid>

                  {/* New Added */}

                  <Grid item lg={4} md={6} xs={12} /*className={classes.callTypeSection}*/>
                    {/* {data &&
                      (data?.patientAppointment?.appointmentChannelTypeId === 'Video Call' ? (
                        <VideocamIcon />
                      ) : (
                        <PhoneIcon />
                      ))} */}

                    {/* <div className={classes.distanceFromIcon}> */}
                    <Typography variant="body2">Cardiologist Name</Typography>
                    <SkeletonLoader isloading={Object.keys(data).length === 0}>
                      <Typography variant="body1">
                        {data?.patientAppointment?.appointmentChannelType}
                      </Typography>
                    </SkeletonLoader>
                    {/* </div> */}
                  </Grid>
                </>
              ),
            },
          ]}
        />
  )
}

export default CoachThisSession
