import EventIcon from '@material-ui/icons/Event';
import SupportIcon from '@material-ui/icons/LiveHelpOutlined';

import { COACH_ROUTES, DOCTOR_ROUTES, OPS_ROUTES, ROUTES } from 'constants/app';

import SettingsIcon from 'assets/images/logos/settings.svg';
import InboxIcon from 'assets/images/logos/inbox.svg';
import AlertIcon from 'assets/images/logos/alerts.svg';
import MemberlistIcon from 'assets/images/logos/member.svg';
import HomeIcon from 'assets/images/logos/home.svg';
import ResourceIcon from 'assets/images/logos/resource.svg';
import SchedulerIcon from 'assets/images/icons/scheduler.svg';
import FeedbackIcon from 'assets/images/icons/feedback.svg';
import ConsultationIcon from 'assets/images/icons/consultation.svg';
import AudioImage from 'assets/images/Icon.Left.svg';
import EKGs from 'assets/images/logos/ecg.svg';
import WarningIcon from '@material-ui/icons/Warning';
// import TeleConnect from 'assets/images/icons/teleconnect.svg';

export const fetchConfigs = (
  isAdmin,
  isTeleConnect,
  isECGReview,
  isAudioReview,
  history,
  unreadCount,
  status,
  alertCount,
  ekgCount,
  audioCount,
) => {
  const coachConfig = {
    main: [
      {
        condition: isTeleConnect,
        navigateTo: COACH_ROUTES.TELEKARDIA_DASHBOARD,
        icon: HomeIcon,
        title: 'Home',
      },
      {
        condition: isTeleConnect,
        navigateTo: COACH_ROUTES.COACH_MEMBER_LIST,
        icon: MemberlistIcon,
        title: 'Members',
      },
      {
        condition: isTeleConnect,
        navigateTo: ROUTES.CHAT_INBOX,
        icon: InboxIcon,
        unreadCount: unreadCount,
        status: status,
        title: 'Inbox',
      },
      {
        condition: isTeleConnect,
        navigateTo: COACH_ROUTES.COACH_SCHEDULE,
        icon: EventIcon,
        title: 'Schedule',
      },
      {
        condition: isAdmin,
        navigateTo: COACH_ROUTES.ADMIN_TELEKARDIA_DASHBOARD,
        icon: HomeIcon,
        title: 'Home',
        style: { marginLeft: '3px' },
      },
      {
        condition: isAdmin,
        navigateTo: COACH_ROUTES.ADMIN_MEMBER_LIST,
        icon: MemberlistIcon,
        title: 'Members',
      },
      {
        condition: isAdmin,
        navigateTo: COACH_ROUTES.ADMIN_FEEDBACK,
        icon: FeedbackIcon,
        title: 'Feedback',
        style: { marginLeft: '3px' },
      },
      {
        condition: isAdmin,
        navigateTo: COACH_ROUTES.ADMIN_SESSIONS,
        icon: ConsultationIcon,
        title: 'Sessions',
      },
      {
        condition: isAdmin,
        navigateTo: COACH_ROUTES.ADMIN_CALENDAR,
        icon: SchedulerIcon,
        title: 'Scheduler',
        style: { marginLeft: '3px' },
      },
      {
        condition: isAdmin || isTeleConnect,
        navigateTo: `${ROUTES.ALERT}/list`,
        icon: AlertIcon,
        alertCount: alertCount,
        title: 'Alerts',
      },
    ],
    footer: [
      {
        navigateTo: COACH_ROUTES.COACH_RESOURCES,
        icon: ResourceIcon,
        title: 'Resources',
      },
      {
        navigateTo: ROUTES.SETTINGS,
        icon: SettingsIcon,
        title: 'Settings',
      },
    ],
  };
  const doctorConfig = {
    main: [
      {
        condition: isAdmin && isTeleConnect,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ADMIN_DASHBOARD,
        icon: HomeIcon,
        title: 'Home',
      },
      {
        condition: !isAdmin && isTeleConnect,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_DASHBOARD,
        icon: HomeIcon,
        title: 'Home',
      },
      {
        condition: isECGReview,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ECG,
        icon: EKGs,
        ekgCount: ekgCount,
        title: 'ECGs',
      },

      {
        condition: isAudioReview,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_AUDIO,
        icon: AudioImage,
        title: 'Audio',
        audioCount: audioCount,
        style: { marginLeft: '3px' },
      },
      {
        condition: isAdmin && isTeleConnect,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ADMIN_FEEDBACKS,
        icon: FeedbackIcon,
        title: 'Feedback',
        style: { marginLeft: '3px' },
      },

      {
        condition: isAdmin && isTeleConnect,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ADMIN_CONSULTATIONS,
        icon: ConsultationIcon,
        title: 'Consultations',
      },
      {
        condition: isAdmin && isTeleConnect,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ADMIN_CALENDAR,
        icon: SchedulerIcon,
        title: 'Scheduler',
        style: { marginLeft: '3px' },
      },

      {
        condition: !isAdmin && isTeleConnect,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ALL_APPOINTMENT,
        icon: ConsultationIcon,
        title: 'Consultations',
      },
      {
        condition: !isAdmin && isTeleConnect,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_CONSULTATION,
        icon: EventIcon,
        title: 'Schedule',
      },
      {
        condition: isAdmin,
        navigateTo: DOCTOR_ROUTES.TELEKARDIA_ADMIN_REPORT,
        icon: WarningIcon,
        title: 'Reports',
      },
      {
        condition: !isAdmin && isTeleConnect,
        href: 'https://kardiapro.zendesk.com/',
        target: '_blank',
        icon: SupportIcon,
        title: 'Support',
      },
    ],
    footer: [
      {
        navigateTo: DOCTOR_ROUTES.DOCTOR_RESOURCES,
        icon: ResourceIcon,
        title: 'Resources',
      },
      {
        navigateTo: ROUTES.SETTINGS,
        icon: SettingsIcon,
        title: 'Settings',
      },
    ],
  };
  const opsConfig = {
    main: [
      {
        condition: isAdmin && !isTeleConnect,
        navigateTo: OPS_ROUTES.OPS_SHIPPING,
        icon: MemberlistIcon,
        title: 'Member list',
      },
    ],
    footer: [],
  };
  return { coachConfig, doctorConfig, opsConfig };
};
