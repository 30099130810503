
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import {
  useGridSelector,
  useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';
import { MuiTableComponent } from 'screens/common/CustomUI';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Table_HEADERNAME } from './TableConsts';
import { formatDate } from 'utilities/Constants';
import DetailPanelContent from './DetailPanelContent';
import { convertToLocalDate, getDateString } from './Utilities';

export const TableRowText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Work Sans',
  // padding:"inherit"
}));

export const headertext = styled(Typography)(({ theme }) => ({
  display: 'none',
}));

const groupDataByDate = (data) => {
  const groupedData = data.reduce((acc, curr) => {
    // This logic is written to Apply the offset (in seconds) to the recorded_at date
    const localDate = convertToLocalDate(curr.recorded_at, curr.recorded_at_offset);

    // This logic is written to Convert the adjusted date to the desired format (ignoring time)
    const date = getDateString(localDate);

    if (!acc[date]) {
      acc[date] = {
        date,
        records: [],
        avgSystolic: 0,
        avgDiastolic: 0,
      };
    }
    acc[date].records.push(curr);
    acc[date].avgSystolic += curr.systolic;
    acc[date].avgDiastolic += curr.diastolic;
    return acc;
  }, {});

  // Calculate averages
  Object.values(groupedData).forEach((group) => {
    group.avgSystolic /= group.records.length;
    group.avgDiastolic /= group.records.length;
  });

  return Object.values(groupedData);
};


export default function BloodPressureUpdatedTable({ data, avgSystolicvalues, avgDystolicvalues }) {
  const groupedData = groupDataByDate(data);


  // This section calculates the outer table rows Data
  const Outerrows = groupedData.map((group, index) => ({
    id: index,
    date: group.date,
    avgbp: ` ${Math.round(group.avgSystolic)}/${Math.round(group.avgDiastolic)}`,
    // avgbp: `${avgSystolicvalues ? avgSystolicvalues?.toFixed(2) : '--'} / ${avgDystolicvalues ? avgDystolicvalues?.toFixed(2) : '--'}`,
    recordings: group.records.length,
  }));
  const getDetailPanelContent = (params) => <DetailPanelContent row={params.row} data={data} avgDystolicvalues={avgDystolicvalues} avgSystolicvalues={avgSystolicvalues} />;


  const getDetailPanelHeight = React.useCallback((params) => {
    const recordings = params.row.recordings || 0; // Default to 0 if no recordings
    const rowHeight = 60; // Each row height is 52px
    return (recordings * rowHeight) + (recordings === 0 ? 0 : recordings === 1 ? 150 : 140);// Multiply row height by number of recordings
  }, []);
  //This returns Outer table
  return (
    <Box
      style={{
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '12px',
        overflow: 'unset',
      }}
    >
      <MuiTableComponent
        style={{ height: 'fitContent', overflow: 'unset', backgroundColor: 'White' }}
        rows={Outerrows}
        columns={columns}
        getDetailPanelContent={getDetailPanelContent}
        columnToolbar={true}
        densityToolbar={true}
        // columnVisibilityModel={columnVisibilityModel}
        // onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        getDetailPanelHeight={(params) => getDetailPanelHeight(params)}
      />
    </Box>
  );
}

function CustomDetailPanelToggle(props) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  const hasDetail = React.isValidElement(contentCache[id]);


  return (
    <IconButton
      size="Large"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? "Close" : "Open"}
    >
      <ArrowRightIcon style={{ height: "52px", width: "38px" }}
        sx={{
          transform: `rotateZ(${isExpanded ? 90 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
}

// Outer Table Colums
const columns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    headerName: Table_HEADERNAME.TOGGLE,
    width: 200,
    renderCell: (params) => (
      <CustomDetailPanelToggle id={params.id} value={params.value} />
    ),
  },
  {
    field: "date",
    headerName: Table_HEADERNAME.DATE,
    width: 200,
    type: "dateTime",
    renderCell: (params) => (
      <TableRowText variant="body2">{formatDate(params?.row?.date) || '--'}</TableRowText>
    ),
  },

  {
    field: "avgbp",
    headerName: Table_HEADERNAME.AVGBP,
    width: 200,
    type: "number",
    renderCell: (params) => (
      <TableRowText variant="body2">{params?.row?.avgbp || '--'}</TableRowText>
    ),
  },
  {
    field: "recordings",
    headerName: Table_HEADERNAME.RECORDINGS,
    width: 200,
    type: "number",
    renderCell: (params) => (
      <TableRowText variant="body2">{params?.row?.recordings || '--'}</TableRowText>
    ),
  },
];
