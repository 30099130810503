/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from '@material-ui/core';
import AudioEngine from '@stethio/spectrogram-drawer';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeDown from '@mui/icons-material/VolumeDown';
import SettingsIcon from '@mui/icons-material/Settings';
import SliderSizes from 'components/Shared/Slider/Slider';
import MenuListComposition from 'components/Common/Dropdown/Dropdown';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
// import css
import './Spectrogram.css';
import LinearDeterminate from 'components/Shared/Slider/Progress';
import { setPlaybackSpeed } from 'store/actions/audio';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';

const Spectrogram = ({
  canvasHeader,
  fileURL,
  canvas,
  pausePlayBetweenMultipleAudioInstances,
  isActive,
}) => {
  const dispatch = useDispatch();

  const [audioEngine, setAudioEngine] = useState(null);
  const [SKD_KEY] = useState(process.env.REACT_APP_STETHIO_SDK_REGISTRATION_KEY);
  const [selectedAudioFileSource, setAudioFileSource] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [fileBufferLoading, setFileBufferLoading] = useState(false);
  const [fileLoaded, setFileLoad] = useState(false);
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [filePlayed, setFilePlayed] = useState(false);
  const [duration, setDuration] = useState(null);
  const [speed, setSpeed] = useState(1);
  const [timeLimit, setTimeLimit] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [volume, setVolume] = useState(20);

  const playbackSpeed = useSelector((state) => state.audio?.playbackSpeed);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    initHeartData();
  }, []);

  const initHeartData = async () => {
    let constraints = { audio: true };
    let audioEngine = await new AudioEngine().initAudioEngine(canvas, SKD_KEY);
    if (audioEngine) {
      audioEngine.setAutoGainFlag(false); // By default, the gain is enabled
      audioEngine.setFilterFlag(false);
      await navigator.mediaDevices.getUserMedia(constraints);
      audioEngine
        .startIt(false)
        .then((res) => {
          audioEngine.loadOpusDecoder();
        })
        .catch((err) => {
          console.log('Start AudioEngine error: ', err);
        });
      setAudioEngine(audioEngine);
      setAudioContext(new AudioContext());
    }
  };

  useEffect(() => {
    playbackSpeed > 0 && setSpeed(playbackSpeed);
  }, [playbackSpeed]);

  /**
   * if isActive prop is false that means other audio instance is being played
   * thus this instance should get paused.
   */
  useEffect(() => {
    if (!isActive && isPlaying) {
      pause();
    }
  }, [isActive]);

  useEffect(() => {
    if (fileURL !== undefined && fileURL !== null) {
      loadFileURL(fileURL);
    }
  }, [fileURL]);

  // set the URL
  const loadFileURL = (fileURL) => {
    setAudioFileSource(fileURL);
  };

  const getPlay = () => {
    pausePlayBetweenMultipleAudioInstances(); //when an instance is played this calling method will notify parent component (Review.jsx) to set this instance as active

    setIsPlaying(true);
    if (!filePlayed) {
      fetch(selectedAudioFileSource)
        .then((response) => {
          setFileBufferLoading(true);
          setFilePlayed(true);
          return response.arrayBuffer();
        })
        .then((arrayBuffer) => {
          setFileLoad(true);
          return audioContext.decodeAudioData(arrayBuffer);
        })
        .catch((err) => {
          setFileBufferLoading(false);
          setIsPlaying(false);
          setErrorMsg(true);
        })
        .then((audioBuffer) => {
          speedSelected(speed);
          audioEngine.testAudioInput(audioBuffer.getChannelData(0));
          setDuration(Math.ceil(audioBuffer?.duration));
          setTimeLimit(Math.ceil(audioBuffer?.duration));
          setFileBufferLoading(false);
        })
        .catch((err) => {
          setIsPlaying(false);
          setErrorMsg(true);
        });
    } else {
      audioEngine.resume();
    }
  };

  const pause = () => {
    setIsPlaying(false);
    audioEngine.pause();
  };

  const handleVolumeSet = (event) => {
    if (audioEngine) {
      setVolume(event.target.value);
      audioEngine.volumeSet(1 + event.target.value / 100);
    }
  };

  const speedSelected = (value) => {
    dispatch(setPlaybackSpeed(value));
    if (audioEngine) {
      audioEngine.playbackSpeedChange(parseFloat(value));
    }
  };

  useEffect(() => {
    if (duration && isPlaying) {
      let interval = setInterval(() => {
        if (timeLimit > 0) {
          setTimeLimit((prevtimeLimit) => prevtimeLimit - 1);
        } else {
          clearInterval(interval);
        }
      }, 1000);

      // Clear the interval after 14 seconds (14000 milliseconds)
      //  setTimeout(() => {
      //   clearInterval(interval);
      // }, timeLimit);
      return () => {
        clearInterval(interval); // Cleanup: clear the interval when the component unmounts
      };
    }
  }, [timeLimit, isPlaying]);

  /**
   * --> is audio engine is undefined ie. something wrong with sdk's initial load
   *     eg. sdk key expired.
   * --> in this case portal will show audio media player where
   *     stheio audio will be played without wave graph
   *
   * below function is responsible to handle toggle between audio players
   */
  const audioRef = useRef(canvas);

  return (
    <div className="spectrogram_drawer">
      <div className="container">
        <div className="note">
          <Typography variant="body2">Area:{canvasHeader}</Typography>
        </div>
        {errorMsg && <div className="errorMessage">This Audio File cant't be played.</div>}
        {fileBufferLoading && <LinearProgress />}
        {audioEngine ? (
          <>
            <canvas
              id={canvas}
              style={{ border: '1px solid', backgroundColor: 'rgba(0,0,0,0.87)' }}
            ></canvas>
            <div className="bottom_panel">
              <div className="boxFeature">
                {!audioEngine || fileBufferLoading ? (
                  <CircularProgress size={18} thickness={6} sx={{ marginRight: 1 }} />
                ) : isPlaying ? (
                  <PauseIcon
                    style={{ color: 'rgba(0, 0, 0, 0.6)', cursor: 'pointer' }}
                    onClick={() => pause()}
                  />
                ) : (
                  <PlayArrowIcon
                    style={{ color: 'rgba(0, 0, 0, 0.6)', cursor: 'pointer' }}
                    onClick={() => getPlay()}
                  />
                )}

                <LinearDeterminate
                  size={'75%'}
                  variant={'small'}
                  duration={duration}
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                  setFilePlayed={setFilePlayed}
                  timeLimit={timeLimit}
                />

                <div style={{ color: '#00000099', fontSize: 12, marginLeft: 5 }}>{`00:${
                  timeLimit === null
                    ? '00'
                    : `${
                        typeof timeLimit === 'number' && timeLimit >= 0 && timeLimit <= 9
                          ? `0${timeLimit}`
                          : timeLimit
                      }`
                }`}</div>
                <VolumeDown style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: 10 }} />
                <SliderSizes
                  size={'13%'}
                  variant={'small'}
                  value={volume}
                  onChange={handleVolumeSet}
                  disabled
                />
                <SettingsIcon
                  style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: 10 }}
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? 'composition-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                />
              </div>
              {open && (
                <MenuListComposition
                  getValue={speedSelected}
                  setValue={speed}
                  open={open}
                  anchorRef={anchorRef}
                  handleToggle={handleToggle}
                  setOpen={setOpen}
                />
              )}
            </div>
          </>
        ) : (
          <div style={{ position: 'relative' }}>
            <audio ref={audioRef} src={fileURL} controls={true} type="audio/mp3"></audio>
          </div>
        )}
      </div>
    </div>
  );
};

export default Spectrogram;
