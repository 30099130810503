import * as React from 'react';
import { MuiTableComponent } from 'screens/common/CustomUI';
import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import DarkRed from '../../../assets/images/DarkRedCircle.png';
import Greencircle from '../../../assets/images/Greencircle.png';
import Lightred from '../../../assets/images/LightRedCircle.png';
import yellocircle from '../../../assets/images/YellowCircle.png';
import {
  getBloodPressureSeverityDisplayText,
  getBloodPressureSourceDisplayText,
} from 'constants/app';
import { Table_HEADERNAME } from './TableConsts';
import { datetimeFormat } from 'utilities/Utilities';
import LightOrangeIcon from '../../../assets/images/LightOragneUpdated.png';
import LightBlueIcon from '../../../assets/images/BluecircleIcon.png';
import GreyIcon from '../../../assets/images/LightGreayIcon.png';
import EcgLoader from 'components/Shared/EcgLoader';
import { TableRowText } from './BloodPressureUpdatedTable';
import { convertToLocalDate, getDateString, getTimeString } from './Utilities';

const classes = {
  root: `MuiDataGrid-root`,
};

const Root = styled('div')(({ theme, height }) => ({
  [`&.${classes.root}`]: {
    '& .MuiDataGrid-root': {
      height: 'fit-content',
      border: 'none',
      cursor: 'pointer',
      marginTop: '-30px',
      '& .MuiDataGrid-main': {
        borderBottom: `4px solid var(--Light-Text-Secondary, rgba(0, 0, 0, 0.60))`,
        borderLeft: `1px solid var(--Light-Text-Secondary, rgba(0, 0, 0, 0.60))`,
        height: 'fit-content',
        margin: '0 0 0 4px',
        paddingLeft: '30px',
      },
    },

    '& .MuiDataGrid-detailPanel': {
      overflow: 'hidden',
      // height: "auto",
    },
    '& .MuiDataGrid-groupingCriteria': {
      width: 'calc(100% - 50px)', // Stop at inner data boundary
      borderBottom: '1px solid grey', // Line styling
    },
    '& .MuiDataGrid-row': {
      overflow: 'hidden', // Remove scrolls from rows
    },
    '& .MuiDataGrid-virtualScroller': {
      overflowY: 'auto', // Control scrolling at the grid level
    },
  },
}));

export default function DetailPanelContent({ row: rowProp, data }) {
  const [innerTableData, setInnerTableData] = React.useState([]);
  const [loading, setLoading] = React.useState(true); // Data loading state
  const [imagesLoaded, setImagesLoaded] = React.useState(false); // Image loading state
  const [precomputedData, setPrecomputedData] = React.useState([]); // Precomputed displayText and imageSrc

  const imageSources = [
    DarkRed,
    Lightred,
    LightOrangeIcon,
    yellocircle,
    Greencircle,
    LightBlueIcon,
    GreyIcon,
  ];
  const getCategoryImage = (displayText) => {
    try {
      switch (displayText) {
        case 'Hypertensive Crisis':
          return require('../../../assets/images/DarkRedCircle.png');
        case 'Hypertension 2':
          return require('../../../assets/images/LightRedCircle.png');
        case 'Hypertension 1':
          return require('../../../assets/images/LightOragneUpdated.png');
        case 'Elevated':
          return require('../../../assets/images/YellowCircle.png');
        case 'Normal':
          return require('../../../assets/images/Greencircle.png');
        case 'Low':
          return require('../../../assets/images/BluecircleIcon.png');
        default:
          return require('../../../assets/images/LightGreayIcon.png'); // Default or grey icon
      }
    } catch (err) {
      console.error('Error loading image:', err);
      return ''; // Fallback in case of error
    }
  };

  // Function to preload images
  const preloadImages = (sources) => {
    return Promise.all(
      sources.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      }),
    );
  };

  // Function to calculate displayText and imageSrc
  const computeDisplayData = (tableData) => {
    return tableData.map((row) => {
      const displayText = getBloodPressureSeverityDisplayText(row?.category || '');
      const imageSrc = getCategoryImage(displayText);
      return { ...row, displayText, imageSrc }; // Include displayText and imageSrc in each row
    });
  };

  async function getinerTableData(date, data) {
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    // This logic is written to Adjust the recorded_at date using recorded_at_offset for each entry
    const filteredData = data.filter((entry) => {
      const localDate = convertToLocalDate(entry.recorded_at, entry.recorded_at_offset);
      return getDateString(localDate) === date; // Compare adjusted date with the outer table's date
    });

    return filteredData.map((entry, index) => ({
      id: index,
      date: convertToLocalDate(entry.recorded_at, entry.recorded_at_offset),
      source: entry.source || 'Manual',
      category: entry.severity || 'Unknown',
      bpm: entry.pulse,
      systolic: entry.systolic,
      diastolic: entry.diastolic,
      recorded_at_offset: entry.recorded_at_offset,
    }));
  }

  React.useEffect(() => {
    let isMounted = true;

    // Load data and images
    (async () => {
      setLoading(true); // Set loading to true before fetching data

      const result = await getinerTableData(rowProp.date, data);
      if (!isMounted) return;

      const computedResult = computeDisplayData(result);
      setInnerTableData(computedResult);
      setLoading(false); // Data fetching complete

      // Now preload the images
      try {
        await preloadImages(imageSources);
        setImagesLoaded(true); // Set imagesLoaded to true after all images are loaded
      } catch (error) {
        console.error('Error loading images', error);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [rowProp.date]);

  const insideColumns = [
    {
      field: 'date',
      headerName: Table_HEADERNAME.DATE,
      width: 300,
      type: 'dateTime',
      headerAlign: 'center',

      renderCell: (params) => {
        const { displayText, imageSrc } = params.row;
        return (
          <Box style={{ display: 'flex', gap: '30px' }}>
            <Box>
              <img
                src={imageSrc}
                alt={displayText || 'unknown'}
                style={{ width: '20px', height: '20px' }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = require('../../../assets/images/LightGreayIcon.png'); // Fallback image
                }}
              />
            </Box>
            <Box style={{ marginLeft: '4px' }}>
              <TableRowText variant="body2">
                {datetimeFormat(params?.row?.date) || '--'}
              </TableRowText>
            </Box>
          </Box>
        );
      },
    },

    {
      field: 'source',
      headerName: Table_HEADERNAME.SOURCE,
      type: 'string',
      width: 200,
      align: 'left',
      renderCell: (params) => (
        <TableRowText variant="body2">
          {getBloodPressureSourceDisplayText(params?.row?.source) || '--'}
        </TableRowText>
      ),
    },
    {
      field: 'category',
      headerName: Table_HEADERNAME.CATEGORY,
      type: 'string',
      width: 200,
      align: 'left',
      renderCell: (params) => (
        <TableRowText variant="body2">
          {getBloodPressureSeverityDisplayText(params?.row?.category) || '--'}
        </TableRowText>
      ),
    },
    {
      field: 'bpm',
      headerName: Table_HEADERNAME.BPM,
      type: 'number',
      width: 150,
      // align:'left',
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.bpm || '--'}</TableRowText>
      ),
    },
    {
      field: 'systolic',
      headerName: Table_HEADERNAME.SYSTOLIC,
      type: 'number',
      width: 150,
      // align:'left',
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.systolic || '--'}</TableRowText>
      ),
    },
    {
      field: 'diastolic',
      headerName: Table_HEADERNAME.DIASTOLIC,
      type: 'number',
      width: 150,
      // align:'left',
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.diastolic || '--'}</TableRowText>
      ),
    },
  ];

  //This return inner table

  return (
    <>
      {loading || !imagesLoaded ? (
        <Box style={{ padding: '30px' }}>
          <EcgLoader />
        </Box>
      ) : (
        <Root className={classes.root}>
          <Box style={{ padding: '30px' }}>
            <MuiTableComponent
              style={{ backgroundColor: 'none', overflow: 'unset' }}
              columns={insideColumns}
              rows={innerTableData}
              autoHeight
            />
          </Box>
        </Root>
      )}
    </>
  );
}
