import { Typography, styled } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { GoAPI, KccGoAPI } from 'Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import ModalComponent from 'components/Common/modal/ModalComponent';
import TeamAlert from 'components/Shared/Alert/TeamAlert';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MuiTableComponent } from 'screens/common/CustomUI';

const Root = styled('div')(({ theme }) => ({
  '& .MuiDataGrid-root': {
    margin: 'unset !important',
    border: '1px solid lightgyey !important',
    borderRadius: '5px',
  },
}));
const InvitedProviders = (props) => {
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      hide: true,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 250,
      editable: false,
      filterable: false,
      type: 'string',
      renderCell: (params) => <Typography variant="body1">{params.row.firstName}</Typography>,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 250,
      editable: false,
      filterable: false,
      type: 'string',
      renderCell: (params) => <Typography variant="body1">{params.row.lastName}</Typography>,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      // editable: false,
      filterable: false,
      type: 'string',
      renderCell: (params) => <Typography variant="body1">{params?.row?.email}</Typography>,
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      sorting: false,
      // hide: true,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          // icon={<SecurityIcon />}
          label="Revoke invite"
          onClick={revokeInvite(params.row.id)}
          // disabled={params.row.status === 3}
          showInMenu
        />,
      ],
    },
  ];

  const [loading, setloading] = useState([]);
  const [filteredRow, setfilteredRow] = useState([]);
  const teamId = useSelector((state) => state.user.team.id);
  const userType = useSelector((state) => state.user.chatProfile.userType);
  const [showAlert, setShowAlert] = useState({
    data: '',
    error: false,
  });

  useEffect(() => {
    getInvitedProviderList();
  }, []);

  // useEffect(() => {
  //   props.inviteSent && getInvitedProviderList();
  // }, [props.inviteSent]);

  const revokeInvite = useCallback(
    (inviteId) => () => {
      confirmRevoke(inviteId);
    },
    [],
  );
  const [confirmRevokeInvite, setConfirmRevokeInvite] = useState(false);
  const [confirmRevokeInviteParameter, setConfirmRevokeInviteParameter] = useState(false);
  const confirmRevoke = (inviteId) => {
    setConfirmRevokeInvite(true);
    setConfirmRevokeInviteParameter(inviteId);
  };
  const cancleRevoke = () => {
    setConfirmRevokeInvite(false);
  };

  const getInvitedProviderList = () => {
    setloading(true);
    KccGoAPI.get(`i/v1/teams/${teamId}/invites?userType=${userType}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        setfilteredRow(res.data.invites);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };
  const editInvitedProviderList = (inviteId) => {
    setloading(true);
    GoAPI.delete(`/i/v1/invites/${inviteId}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        res.status === 200 && getInvitedProviderList();
        setShowAlert({
          data: 'Pending invite has been revoked successfully ',
          error: false,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 5000);
        setConfirmRevokeInvite(false);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Root>
      {showAlert.data !== '' && <TeamAlert title={showAlert.data} error={showAlert.error} />}

      <SectionMain
        headerElement={{
          headerText: 'Pending Invites',
        }}
        bodyElement={[
          {
            childElements: (
              <MuiTableComponent
                columns={columns}
                rows={filteredRow}
                columnToolbar={false}
                filterToolbar={false}
                densityToolbar={false}
                exportToolbar={false}
                pagination
                loading={loading}
                paginationMode="server"
              />
            ),
          },
        ]}
      />
      <ModalComponent
        headerText={'Revoke Invite?'}
        modalText={'Are you sure you want to revoke this pending invite?'}
        open={confirmRevokeInvite}
        handleClose={cancleRevoke}
        cancelButtonText={'cancel'}
        confirmButtonText={'Yes, Revoke'}
        confirmHandler={editInvitedProviderList}
        confirmPayload={confirmRevokeInviteParameter}
      />
    </Root>
  );
};

export default InvitedProviders;
