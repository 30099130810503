import React, { useEffect, useState } from 'react';
import { columnGidLayout } from './formUtility';
import { Box, Chip, Grid, styled } from '@mui/material';
import { useParams } from 'react-router';
import { completed, pending } from 'constants/app';
import { reviewFieldUpdate } from 'store/actions/EkgReviewActions';
import { useDispatch } from 'react-redux';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '10px',
  padding: '8px 16px 8px 1px',
  // margin: '1em',
  '& .css-a5rdam-MuiGrid-root': {
    backgroundColor: 'white',
    marginLeft: '0px',
  },
}));

const RenderSelectedInputs = ({ values, completedValue, column = 0, setSelectedOptions, name ,disabled}) => {
  const dispatch = useDispatch();
  const { reviewStatus } = useParams();
  const [pendingSelection, setPendingSelection] = useState([]);

  useEffect(() => {
    dispatch(reviewFieldUpdate(false));
    setPendingSelection([completedValue] ?? []);
    setSelectedOptions((prev) => ({ ...prev, [name]: completedValue }));
  }, [completedValue]);

  const radioSelection = (selectedRadioItem) => {
    reviewStatus === pending && dispatch(reviewFieldUpdate(true));
    setPendingSelection(values.filter((option) => option === selectedRadioItem));
    setSelectedOptions((prev) => ({ ...prev, [name]: selectedRadioItem }));
  };

  return (
    <Container>
      <Box alignitems={'flex-start'} flexdirection={'row'}>
        <Grid container spacing={1}>
          {values &&
            values.map((item, i) => {
              return (
                <Grid key={i} item xs={columnGidLayout(column)}>
                  <Chip
                    label={item}
                    disabled={disabled}
                    onClick={reviewStatus === completed ? () => null : () => radioSelection(item)}
                    variant="contained"
                    style={{
                      backgroundColor: pendingSelection.includes(item)
                        ? '#174E8C'
                        : 'rgba(0, 0, 0, 0.1)',
                      color: pendingSelection.includes(item) ? 'white' : 'rgba(0, 0, 0, 0.87)',
                    }}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Container>
  );
};

export default RenderSelectedInputs;
