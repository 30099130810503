import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tabs, Tab, Chip, Typography } from '@mui/material';
// import RefreshIcon from '@mui/icons-material/Refresh';
import RefreshIcon from 'assets/images/icons/refresh-header.svg';

import TabPanel from 'components/TabPanel/TabPanel';
import { COMPLETTED, DOCTOR_ROUTES, INCOMMING } from 'constants/app';
import EcgInboxTable from 'components/ekg/EkgListTable/EcgInboxTable';
import { setTabState } from 'store/actions';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import BasicBreadcrumbs from 'components/Shared/BreadCrumb/BreadCrumb';
import { refreshEcgInboxTable } from 'store/actions/EkgListActions';
import moment from 'moment';

const EkgList = () => {
  const dispatch = useDispatch();

  const tabState = useSelector((state) => state.ekgList.tabState);
  const [value, setValue] = useState(tabState === INCOMMING ? 0 : 1);

  //total incoming request count. to be shown with tab value
  const notstartedAndOverdueCount = useSelector(
    (state) => state.ekgListTable.notstartedAndOverdueCount,
  );
  const { isAdmin } = useSelector((state) => state.user.profile.permissions);
  const refreshTime = useSelector((state) => state.ekgList?.refreshTime);

  // setting incomming and completed tab in a state
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const IncomingTotatCountBadge = () => {
    return (
      <Chip
        sx={{ marginLeft: '10px' }}
        label={notstartedAndOverdueCount}
        color={tabState === INCOMMING ? 'primary' : 'default'}
      />
    );
  };
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px',
        }}
      >
        <BasicBreadcrumbs
          data={[
            {
              type: 'image',
              url: isAdmin
                ? DOCTOR_ROUTES.TELEKARDIA_ADMIN_DASHBOARD
                : DOCTOR_ROUTES.TELEKARDIA_DASHBOARD,
              src: 'Home',
            },
            { type: 'text', name: 'ECG Inbox' },
          ]}
        />
        <div style={{ display: 'flex', columnGap: '16px', marginRight: '30px' }}>
          <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
            {' '}
            Last Refreshed:{' '}
            <b>
              {'  '}
              {moment(refreshTime).format('hh:mm A')}
            </b>
          </Typography>

          <RefreshIcon onClick={() => dispatch(refreshEcgInboxTable())} />
        </div>
      </div>
      <SectionMain
        headerElement={{
          childElements: (
            <Tabs
              value={value}
              onChange={handleChange}
              selectionFollowsFocus
              aria-label="incomign and completed ecg review tabs"
            >
              <Tab
                label={INCOMMING}
                icon={<IncomingTotatCountBadge />}
                iconPosition="end"
                onClick={() => dispatch(setTabState(INCOMMING))}
              />
              <Tab label={COMPLETTED} onClick={() => dispatch(setTabState(COMPLETTED))} />
            </Tabs>
          ),
        }}
        bodyElement={[
          {
            childElements: (
              <Box sx={{ width: '100%' }}>
                <TabPanel value={value} index={0}>
                  <EcgInboxTable status={INCOMMING} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <EcgInboxTable status={COMPLETTED} />
                </TabPanel>
              </Box>
            ),
          },
        ]}
      />
    </>
  );
};

export default EkgList;
