import React from 'react';
import { Strophe } from 'strophe.js';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { TableCell } from './MemberList';
import styles from './chat.module.css';
import ListLoader from './ListLoader';
import { getFullJid } from 'providers/chat';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { parseName } from 'utilities/Utilities';

const ListItem = (props) => {
  const { item, messagePoolObject, unreadMsgCount, status, chat, onClick } = props;
  let { name, chatUserId } = item;

  return (
    <TableRow>
      <TableCell style={{ width: 350 }} onClick={onClick}>
        <div
          style={{
            fontWeight:
              status === Strophe.Status.CONNECTED && unreadMsgCount[getFullJid(chatUserId)]
                ? 'bold'
                : 'normal',
            fontSize: '14px',
            fontFamily: 'Work Sans',
          }}
        >
          {parseName(name.split(' ')[0], name.split(' ')[1]) || item.chatUserId}
        </div>
        {status === Strophe.Status.CONNECTED && unreadMsgCount[getFullJid(chatUserId)] && (
          <div className={styles.count}>
            {unreadMsgCount[getFullJid(chatUserId)] > 0 && unreadMsgCount[getFullJid(chatUserId)]}
          </div>
        )}
      </TableCell>
      <TableCell align="left" style={{ fontSize: '14px', fontFamily: 'Work Sans' }}>
        {chat[`loader-${chatUserId}`] ? (
          <ListLoader />
        ) : messagePoolObject[chatUserId]?.text.includes('asset') ? (
          <AttachmentIcon />
        ) : (
          messagePoolObject[chatUserId]?.text
        )}
      </TableCell>
      <TableCell style={{ width: 200, fontSize: '14px', fontFamily: 'Work Sans' }} align="left">
        {chat[`loader-${chatUserId}`] ? (
          <ListLoader />
        ) : messagePoolObject[chatUserId]?.time ? (
          moment(new Date(messagePoolObject[chatUserId]?.time)).format('lll')
        ) : (
          ''
        )}
      </TableCell>
    </TableRow>
  );
};
export default ListItem;
