/* eslint-disable react/jsx-key */
import * as React from 'react';
import { useHistory } from 'react-router';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { CustomText, Pills, MuiTableComponent } from '../CustomUI';
import { ALERT_NOTE, ALERT_SUBTYPE, COACH_ROUTES } from 'constants/app';
import { useDispatch, useSelector } from 'react-redux';
import { startAdhocCall } from 'store/actions';
import { getMemberProfileDetails, getNote } from 'store/actions/memberProfile';
import Documentation from 'components/Common/ModalView/Documentation';
import { useParams } from 'react-router';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  Button,
  Paper,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  alertsTableInfo,
  alertStatusChange,
  dashboardKpiCount,
  deleteAlert,
  getChatUserId,
} from 'store/actions/escalations';
import { programMap } from 'utilities/Constants';
import { getEKGAlgorithmDeterminationDisplayText } from 'constants/recordingMaps';

export default function Alert() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { alertType } = useParams();
  const profileId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);

  // ========== x-data-grid column definition =========//

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      // hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 260,
      // showMultiFilterOperators: false,
      editable: false,
      type: 'string',
      renderCell: (params) => <CustomText params={params} color="#6387C5" weight={800} />,
    },
    {
      field: 'createdDate',
      headerName: 'Date',
      width: 140,
      // editable: false,
      type: 'date',
      renderCell: (params) => (
        <p style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}>
          {moment(params?.row?.createdDate).format('MM-DD-YYYY')}
        </p>
      ),
    },
    {
      field: 'subTypeName',
      headerName: 'Alert Type',
      width: 140,
      editable: false,
      type: 'string',
      renderCell: (params) => (
        <p style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}>
          {params.row.subTypeName}
        </p>
      ),
    },
    {
      field: 'bloodPressure',
      headerName: 'Blood Pressure',
      width: 160,
      filterable: false,
      type: 'string',
      renderCell: (params) => (
        <p style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}>
          {params.row.bloodPressure}
        </p>
      ),
    },
    {
      field: 'ekgName',
      headerName: 'EKG',
      width: 260,
      editable: false,
      type: 'string',
      sortable: false,
      renderCell: (params) => <Pills params={params} color="#067F6F" column="ekg" />,
    },
    {
      field: 'program',
      headerName: 'Program',
      width: 150,
      editable: false,
      type: 'string',
      sorting: false,
      renderCell: (params) => (
        <p style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}>
          {params.row.program}
        </p>
      ),
    },
    {
      field: 'statusName',
      headerName: 'Status',
      width: 120,
      editable: false,
      type: 'string',
      sorting: false,
      renderCell: (params) => <Pills params={params} color="#c1c1c1" column="status" />,
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      sorting: false,
      hide: true,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          // icon={<SecurityIcon />}
          label="View Profile"
          onClick={viewProfile(params.row)}
          // disabled={params.row.status === 3}
          showInMenu
        />,
        <GridActionsCellItem
          label="Message Member"
          onClick={messageMember(params.row)}
          disabled={params.row.status === 3}
          showInMenu
        />,
        <GridActionsCellItem
          label="Call Member"
          onClick={callMember(params.row)}
          disabled={params.row.status === 3}
          showInMenu
        />,
        <GridActionsCellItem
          label={params.row.noteId === null ? `Add Notes` : `Review Note`}
          onClick={addNotes(params.row)}
          disabled={params.row.status === 3}
          showInMenu
        />,
        <GridActionsCellItem
          label="Dismiss Alert"
          onClick={dismissAlert(params.row)}
          disabled={params.row.status === 3 || params.row.noteId === null}
          showInMenu
        />,
      ],
    },
  ];

  //========== end =============//

  //========== table action callbacks ========//
  // view profile
  const viewProfile = React.useCallback(
    (params) => () => {
      // change alert status to pending if its in open state
      params.status === 1 && alertStatusChange(teamId, profileId, params.id);
      history.push(`${COACH_ROUTES.MEMBER_PROFILE_BASE}/${params?.patientId}`);
    },
    [],
  );

  // message member
  const messageMember = React.useCallback(
    (params) => () => {
      // change alert status to pending if its in open state
      params.status === 1 && alertStatusChange(teamId, profileId, params.id);
      getChatUserId(profileId, params.patientId).then((res) => {
        history.push(`/chat-inbox/${res?.chatUserID}`);
      });
    },
    [],
  );

  //============= call member ============//

  const [callMemberInfo, setCallMemberInfo] = React.useState({ open: false, alertID: '' });
  const callMember = React.useCallback(
    (params) => () => {
      setLoading(true);
      // change alert status to pending if its in open state
      params.status === 1 && setCallMemberInfo({ open: true, alertID: params.id });

      dispatch(getMemberProfileDetails(params.patientId)).then(() => {
        dispatch(startAdhocCall('Call Member', params.patientId));
        setLoading(false);
      });
    },
    [],
  );

  // initial filter
  React.useEffect(() => {
    // when alert table loads user should see only OPEN, PENDING alerts.
    // based on backend logic, followiing are the status values ->
    // 1. open = 1,
    // 2. pending = 2,
    // 3. close = 3
    let initialStatusFIlter = [1, 2];
    setStatusSearch(`&status=${initialStatusFIlter}`);
  }, []);

  // change status call
  React.useEffect(() => {
    if (callMemberInfo.open) {
      alertStatusChange(teamId, profileId, callMemberInfo.alertID).then((res) => {
        res.data.result &&
          alertsTableInfo(
            teamId,
            profileId,
            page,
            pageSize,
            patientSearch,
            alertSubTypeSearch,
            statusSearch,
          ).then((res) => {
            setFilteredRow(
              res.map((item) => {
                return {
                  ...item,
                  program: programMap[item?.programId],
                  ekgName: getEKGAlgorithmDeterminationDisplayText(
                    item?.ekgDetermination,
                    item?.ekgPackage,
                  ),
                  name: `${item?.lastName}, ${item?.firstName}`,
                };
              }),
            );
            settotalRowCount(res.length);
            setLoading(false);
          });
      });
    }
  }, [callMemberInfo]);

  //======= add note and review note callbacks ===========//
  const [openNotes, setopenNotes] = React.useState({
    open: false,
    params: null,
  });
  const addNotes = React.useCallback(
    (params) => () => {
      // handleClickOpen(false); // to replace dismiss modal with add/view notes
      setOpenDismissModal(false);
      setopenNotes({
        open: true,
        params: params,
      });
    },
    [],
  );
  const toggleModal = () => {
    setopenNotes({
      open: !openNotes.open,
    });
    setLoading(true);
    alertsTableInfo(
      teamId,
      profileId,
      page,
      pageSize,
      patientSearch,
      alertSubTypeSearch,
      statusSearch,
    ).then((res) => {
      setFilteredRow(
        res.map((item) => {
          return {
            ...item,
            program: programMap[item?.programId],
            ekgName: getEKGAlgorithmDeterminationDisplayText(
              item.ekgDetermination,
              item.ekgPackage,
            ),
            name: `${item?.lastName}, ${item?.firstName}`,
          };
        }),
      );
      settotalRowCount(res.length);
      setLoading(false);
    });
  };
  //======== end ===========//

  //=======dismiss alert callback function=======//
  const [dismissWindow, setDismissWindow] = React.useState({
    status: null,
    params: null,
  });
  const dismissAlert = React.useCallback(
    (params) => () => {
      setLoading(true);
      getNote(teamId, profileId, params.noteId)
        .then((res) => {
          setLoading(false);
          setDismissWindow({
            status: res.result.status,
            params: params,
          });
          handleClickOpen(true);
        })
        .catch((err) => console.log(err));
    },
    [],
  );

  // dismissing the alert if note is already signed.
  const dismissAlertConfirm = React.useCallback(
    (params) => () => {
      deleteAlert(teamId, profileId, params).then((res) => {
        if (res) {
          handleClickOpen(false);
          dispatch(dashboardKpiCount(teamId, profileId));
        }
      });
    },
    [],
  );

  const [openDismissModal, setOpenDismissModal] = React.useState(false);

  const handleClickOpen = (flag) => {
    setOpenDismissModal(flag);
    if (!flag) {
      setLoading(true);
      alertsTableInfo(
        teamId,
        profileId,
        page,
        pageSize,
        patientSearch,
        alertSubTypeSearch,
        statusSearch,
      ).then((res) => {
        setFilteredRow(
          res.map((item) => {
            return {
              ...item,
              program: programMap[item?.programId],
              ekgName: getEKGAlgorithmDeterminationDisplayText(
                item.ekgDetermination,
                item.ekgPackage,
              ),
              name: `${item?.lastName}, ${item?.firstName}`,
            };
          }),
        );
        settotalRowCount(res.length);
        setLoading(false);
      });
    }
  };

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenDismissModal(false);
  };
  // ========= end ==========//

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });

  //==================pagination server logic & filter logic =================//

  const [loading, setLoading] = React.useState(false);
  const [filteredRow, setFilteredRow] = React.useState([]);
  const [patientSearch, setPatientSearch] = React.useState('');
  const [statusSearch, setStatusSearch] = React.useState('');
  const [alertSubTypeSearch, setalertSubTypeSearch] = React.useState('');

  const [totalRowCount, settotalRowCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const perPageOptions = [10, 20, 50];

  const setFilterModel = (val) => {
    if (
      val.items.length === 0 ||
      (val.items.length > 0 && (val.items[0].value === '' || val.items[0].value === undefined))
    ) {
      // if filter has no values or all existing filter values are removed,
      // then local state responsible to trigger api with filter values
      // needs to be reset.
      setPatientSearch('');
      setStatusSearch('');
    } else {
      // BE api payload understand numerical filters
      // thus need to convert UI string dataType to int data-type
      let statusObject = {
        open: 1,
        pending: 2,
        closed: 3,
      };
      let statusArray = [];
      let patientNameArray = [];

      val.items.forEach((item) => {
        if (item.columnField === 'statusName') statusArray.push(statusObject[item.value]);
        else if (item.columnField === 'name') patientNameArray.push(item.value);
        else {
          setPatientSearch('');
          setStatusSearch('');
        }
      });
      setStatusSearch(`&status=${statusArray}`);
      patientNameArray.length > 0 &&
        setPatientSearch(`&name=${patientNameArray[patientNameArray.length - 1] || ''}`);
    }
  };

  React.useEffect(() => {
    if (alertType !== 'list') {
      // showing filtered alert table on click of dashbpard tiles
      setalertSubTypeSearch(`&subType=${ALERT_SUBTYPE[alertType]}`);
    }
  }, [alertType]);

  const asyncTimer = React.useRef(null);
  React.useEffect(() => {
    setLoading(true);
    //timeout to trigger after 2 src of typing the name filter
    asyncTimer.current = setTimeout(() => {
      alertsTableInfo(
        teamId,
        profileId,
        page,
        pageSize,
        patientSearch,
        alertSubTypeSearch,
        statusSearch,
      ).then((res) => {
        setFilteredRow(
          res.map((item) => {
            return {
              ...item,
              program: programMap[item?.programId],
              ekgName: getEKGAlgorithmDeterminationDisplayText(
                item.ekgDetermination,
                item.ekgPackage,
              ),
              name: `${item?.lastName}, ${item?.firstName}`,
            };
          }),
        );
        settotalRowCount(res.length);
        setLoading(false);
        dispatch(dashboardKpiCount(teamId, profileId));
      });
    }, 1500);
    return () => clearTimeout(asyncTimer.current);
  }, [page, pageSize, patientSearch, alertSubTypeSearch, statusSearch]);

  // initial filter if naviagted from dahsboard kpis based on alert type
  const initialFilter = {
    items: [
      { id: 1, columnField: 'statusName', operatorValue: 'contains', value: 'open' },
      { id: 2, columnField: 'statusName', operatorValue: 'contains', value: 'pending' },
    ],
    linkOperator: 'or',
  };
  //= ======= end =========== //

  return (
    <>
      <MuiTableComponent
        columns={columns}
        rows={filteredRow}
        initialState={{
          filter: {
            // this block is where we put MUI data grid initial UI filters.
            // filterModel: {initialFilter},
          },
          sorting: {
            sortModel: [{ field: 'createdDate', sort: 'asc' }],
          },
        }}
        loading={loading}
        columnToolbar={true}
        filterToolbar={true}
        densityToolbar={true}
        exportToolbar={false}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        rowsPerPageOptions={perPageOptions}
        paginationMode="server"
        rowCount={100}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        ColumnResizeIcon={null}
        onFilterModelChange={(data) => setFilterModel(data)}
      />
      {openNotes.open && (
        <Documentation
          onClose={toggleModal}
          noteId={openNotes.params.noteId === null ? '' : openNotes.params.noteId}
          setopenNotesModal={setopenNotes}
          memberId={openNotes.params.patientId}
          openNotesModal={openNotes.open}
          type={ALERT_NOTE}
          alertSubType={openNotes.params.subTypeName}
          alertId={openNotes.params.id}
          alertDate={moment(openNotes.params.createdDate).format('MM-DD-YYY')}
        />
      )}

      {openDismissModal && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openDismissModal}
        >
          <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#F1F2F3' }} id="customized-dialog-title">
            <Typography variant={'h6'}>Dismiss Alert</Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers sx={{ backgroundColor: '#F1F2F3' }}>
            <Paper
              style={{
                borderRadius: 5,
                padding: '10px 20px',
                textAlign: 'center',
                backgroundColor: 'white !important',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={'h6'}>
                    {dismissWindow &&
                      (dismissWindow.status === 'Draft'
                        ? `You need to sign your note before dismissing this alert.`
                        : `Are you sure you want to dismiss this alert?`)}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  {dismissWindow && dismissWindow.status === 'Signed' && (
                    <Button
                      style={{ color: '#067F6F', border: '1px solid #067F6F', padding: '5px 20px' }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  )}

                  <Button
                    style={{
                      backgroundColor: '#067F6F',
                      color: 'white',
                      marginLeft: '20px',
                      borderColor: '#067F6F',
                      padding: '5px 20px',
                    }}
                    onClick={
                      dismissWindow.status === 'Signed'
                        ? dismissAlertConfirm(dismissWindow.params)
                        : addNotes(dismissWindow.params)
                    }
                  >
                    {dismissWindow && dismissWindow.status === 'Signed' ? `Yes, Dismiss` : `Okay`}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
