import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, Grid, List, TextField } from '@mui/material';

import { getSearchData, setSearchButtonClicked } from 'store/actions';
import { COMPLETTED, INCOMMING, PENDING } from 'constants/app';
import { SEARCH_PLACEHOLDER } from '../constants/EcgInboxConstants';
import {
  InputContainer,
  SearchBarContainer,
  SearchList,
  SearchPaper,
  SearchResultTray,
  SearchResultTrayText,
} from './ecgSearchStyle';

const EkgSearch = ({ clickHandler }) => {
  const [searchValue, setSearchvalue] = useState('');
  const [data, Setdata] = useState([]);
  const [display, setDisplay] = useState(false);

  const profile = useSelector((state) => state.user.profile);
  const tabState = useSelector((state) => state.ekgList.tabState);
  const dataResults = useSelector((state) => {
    return state.ekgSearch.searchresults;
  });

  const isLoadingSearchResults = useSelector((state) => state.ekgSearch.isLoadingSearchResults);
  const isErrorInSearch = useSelector((state) => state.ekgSearch.searchError);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const isAdmin = profile.permissions.isAdmin;

  const openReview = (id, status) => {
    if ((isAdmin && tabState === INCOMMING) || status === PENDING) {
      return;
    }
    if (isAdmin && tabState === COMPLETTED) {
      history.push(`/admin/clinician/ecglist/review/${id}/completed`);
      return;
    }
    if (tabState === COMPLETTED) {
      history.push(`/clinician/ecglist/review/${id}/completed`);
      return;
    }
    history.push(`/clinician/ecglist/review/${id}/incoming`);
  };

  let timer = useRef(null);
  useEffect(() => {
    if (searchValue.length > 0) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        dispatch(getSearchData(searchValue, tabState));
      }, 1000);
      setDisplay(true);
    } else clickHandler(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (dataResults) {
      Setdata(dataResults);
    }
  }, [dataResults]);

  useLayoutEffect(() => {
    setDisplay(false);
    setSearchvalue('');
    Setdata([]);
  }, [tabState]);

  const onSearchClick = () => {
    if (isErrorInSearch) {
      setSearchvalue('');
      setDisplay(false);
      return;
    }
    dispatch(setSearchButtonClicked(true));
    clickHandler(searchValue);
    Setdata([]);
    setDisplay(false);
  };

  const handleClickAway = () => {
    setDisplay(false);
  };

  return (
    <Grid container>
      <SearchBarContainer item xs={12}>
        <InputContainer>
          <TextField
            fullWidth
            label="Search"
            variant="outlined"
            id="search-bar"
            placeholder={t(SEARCH_PLACEHOLDER)}
            value={searchValue}
            onChange={(e) => setSearchvalue(e.target.value)}
            autoComplete="off"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                onSearchClick();
                event.target.blur();
              }
            }}
          />
          {isLoadingSearchResults && display ? (
            <SearchPaper elevation={6}>
              <SearchList>
                <SearchResultTray component="h4">Searching...</SearchResultTray>
              </SearchList>
            </SearchPaper>
          ) : (
            ''
          )}
          {!isLoadingSearchResults && searchValue.length > 1 && display && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <SearchPaper elevation={6}>
                <List>
                  {data && data.length > 0 ? (
                    data.map((data, index) => {
                      return (
                        <SearchList
                          sx={{ cursor: 'pointer' }}
                          key={index}
                          onClick={() => openReview(data.id, data.status)}
                        >
                          <SearchResultTrayText component="h4">
                            {`${data.lastName}, ${data.firstName}`}
                          </SearchResultTrayText>

                          <SearchResultTrayText component="p">{data.reportid}</SearchResultTrayText>
                        </SearchList>
                      );
                    })
                  ) : !isLoadingSearchResults ? (
                    <SearchList>
                      <SearchResultTrayText component="h4">No Results Found</SearchResultTrayText>
                    </SearchList>
                  ) : (
                    ''
                  )}
                </List>
              </SearchPaper>
            </ClickAwayListener>
          )}
        </InputContainer>
      </SearchBarContainer>
    </Grid>
  );
};

export default EkgSearch;
