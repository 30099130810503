import React, { useState, useContext, useEffect, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styles from './chat.module.css';
import { Strophe } from 'strophe.js';
import AsyncChat from 'components/Chat/AsyncChat';
import EcgLoader from 'components/Shared/EcgLoader';
import { Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ChatContext } from 'providers/chat';
import { navigateToHomeScreen, parseName } from 'utilities/Utilities';
import { getLastMessageChatUser } from 'store/actions';
import { useDispatch } from 'react-redux';
import ChatList from './ChatList';
import { useHistory } from 'react-router';
import { COACH_ROUTES } from 'constants/app';
import { pointer } from 'd3';

const ChatInboxDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { chatUserId } = props.match.params || {};
  const [memberList, setMemberList] = useState([]);
  const [memberName, setMemberName] = useState('');
  const [defaultView, setDefault] = useState(false);
  const [userId, setUserId] = useState('');
  const {
    status,
    retryConnection,
    fetchAllChatHistory,
    userId: myChatUserId,
    password,
    setUnreadMsgCount,
    unreadMsgCount,
  } = useContext(ChatContext);
  const { profileId, chat, getChatUserAction, getS3BucketData, upLoadFiles, uploadFileSuccess } =
    props || {};
  const { data, inboxLoadCount, loading } = chat || {};
  const history = useHistory();
  const [retryCount, setRetryCount] = useState(0);
  useEffect(() => {
    const length = data?.length;
    if (status === Strophe.Status.CONNECTED && !length) {
      getChatUserAction(profileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const memberProfileNavigation = (value, e) => {
    history.push(`${COACH_ROUTES.MEMBER_PROFILE_BASE}/${value}`);
  };
  useEffect(() => {
    if (status != Strophe.Status.CONNECTED && status != Strophe.Status.CONNECTING) {
      //Trying to make the connection only 3 times as it will end in infiniate loop if Bosh call makes forbidden request and navigating to Home page gracefully(ASP-614)
      if(retryCount <3){
        retryConnection(myChatUserId, password);
        setRetryCount((prev) => prev + 1);
      }
      else{
        navigateToHomeScreen(history)
      }
    }
  }, [retryCount ,status]);
  
  //==ROUTINE TO LOAD CHAT BEFOREHAND==
  useEffect(() => {
    if (data.length) {
      setMemberList(data);
      inboxLoadCount == 0 && dispatch(getLastMessageChatUser(fetchAllChatHistory));
      //sync unread message count with inbox list incase of any member reassign
      let chatIdKeyMap = {};
      let tempMsgCount = { ...unreadMsgCount };
      data.map((d) => {
        chatIdKeyMap[d.chatUserId] = 0;
      });
      Object.keys(tempMsgCount).map((u) => {
        if (chatIdKeyMap[u] == undefined) {
          delete tempMsgCount[u];
        } else {
          chatIdKeyMap[u] = tempMsgCount[u];
        }
      });
      setUnreadMsgCount(chatIdKeyMap);
    } else {
      setMemberList([]);
    }
    if (chatUserId !== undefined) {
      setMemberName(getName(data, chatUserId));
      setUserId(getUserId(data, chatUserId));
      setDefault(false);
    } else {
      setDefault(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chatUserId]);

  const getName = useCallback((data, chatUserId) => {
    const { name } = data.length && data.find((member) => member.chatUserId === chatUserId);
    // const name = data.length && data[0].name
    return name || chatUserId;
  }, []);
  const getUserId = useCallback((data, chatUserId) => {
    const { userId } = data.length && data.find((member) => member.chatUserId === chatUserId);
    // const name = data.length && data[0].name
    return userId;
  }, []);

  return (
    <Container maxWidth="xl">
      {status !== 5 || loading ? (
        <EcgLoader text={status !== 5 ? 'Connecting...' : 'Loading chats'} />
      ) : (
        <>
          <Paper className={styles.tableContainer}>
            <Grid container className={styles.tableRowHeading}>
              <Grid item lg={3} md={4}>
                <Typography style={{ fontFamily: 'Work Sans', fontSize: '16px', fontWeight: 600 }}>
                  {t('Member')}
                </Typography>
              </Grid>
              <Grid item lg={9} md={8}>
                <Typography style={{ fontFamily: 'Work Sans', fontSize: '16px', fontWeight: 600 , cursor:"pointer" , textDecoration: "underline" }}
                onClick={() => memberProfileNavigation(userId)}
                > {parseName(memberName.split(' ')[0], memberName.split(' ')[1])}
                </Typography>
              </Grid>
            </Grid>
            {!!memberList.length ? (
              <Grid container>
                <Grid item lg={3} md={4} className={styles.tableBody}>
                  <ChatList memberList={memberList} chatUserId={chatUserId} />
                </Grid>
                <Grid item lg={9} md={8} style={{ borderLeft: '1px solid #e9e9e9' }}>
                  {chatUserId && !defaultView ? (
                    <AsyncChat
                      memberName={memberName}
                      userId={userId}
                      chatUserId={chatUserId}
                      getS3BucketData={getS3BucketData}
                    />
                  ) : (
                    defaultView && (
                      <Paper className={styles.defaultView}>
                        <div
                          style={{
                            maxWidth: 300,
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '80%',
                          }}
                        >
                          <Typography
                            style={{ fontFamily: 'WORK SANS', marginTop: '15%' }}
                            variant="body1"
                          >
                            Welcome to <b>Kardia Complete</b> messaging service. <br />
                            Now you can send messages with <b>encryption</b>
                            <hr />
                            <i>Please click on any member to start messaging</i>
                          </Typography>
                        </div>
                      </Paper>
                    )
                  )}
                </Grid>
              </Grid>
            ) : (
              <div className={styles.notFound}>
                <Typography variant="h6">{t('No members found')}</Typography>
              </div>
            )}
          </Paper>
        </>
      )}
    </Container>
  );
};
export default ChatInboxDetails;
