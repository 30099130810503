import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { completed, pending } from 'constants/app';
import { reviewFieldUpdate } from 'store/actions/EkgReviewActions';
import { useDispatch } from 'react-redux';

const RenderSelectedCheckBox = ({ blocks, completedReviewData, handleChange , disabled}) => {

  const { reviewStatus } = useParams();
  const dispatch = useDispatch();

  const [changeState, setChangeState] = useState({});

  useEffect(() => {
    dispatch(reviewFieldUpdate(false));
    if (completedReviewData?.length > 0) {
      setChangeState(transformSelectValues());
    }
  }, [completedReviewData]);

  useEffect(() => {
    handleChange(changeState);
  }, [changeState]);

  const transformSelectValues = () => {
    return blocks.reduce((acc, cur) => {
      acc[cur.name] = completedReviewData?.includes(cur.label);
      return acc;
    }, {});
  };

  const handleSelection = (e) => {
    reviewStatus === pending && dispatch(reviewFieldUpdate(true));
    const { name, checked } = e.target;
    if(name === "none" && checked){
      setChangeState({ 
            none:true,
           seconddegreeone: false,
           firstdegree: false,
           thirddegree: false,
           seconddegeentwo: false
          }
      )
    }
  else
        setChangeState((prev) => ({
          ...prev,
          none:false,
          [name]: checked,
        }));
  };

  return blocks.map((item, i) => (
    <Grid key={i} item xs={6}>
      <FormControlLabel  
        control={
          <Checkbox
            checked={changeState[item.name] ?? false}
            name={item.name}
            onChange={reviewStatus === completed ? null : handleSelection}
            disabled={disabled}
          />
        }
        label={item.label}
      />
    </Grid>
  ));
};

export default RenderSelectedCheckBox;
