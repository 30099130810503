import React from 'react';

const Logo = (props) => (
  <div
    style={{
      fontFamily: "'Work Sans' !important",
      fontStyle: 'normal',
      fontSize: '32px',
      lineHeight: '28px',
      letterSpacing: '0.002em',
      color: '#142A39',
      padding: '0px 10px',
    }}
  >
    <b>AliveCor</b> Services
  </div>
);

export default Logo;
