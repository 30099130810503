import { Grid, Paper, Typography, styled } from '@mui/material';
import React from 'react';
import classes from './Section.module.css';

const HeaderText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Work Sans',
  fontSize: '16px',
  color: 'rgba(0,0,0,0.87)',
  fontWeight: 600,
}));
const SectionBody = ({
  headerText = null,
  subheaderText = null,
  headerElement = null,
  childElements,
  sx = {},
}) => {
  return (
    <Paper className={classes.bodyContainer} sx={sx}>
      {headerText != null && <HeaderText>{headerText}</HeaderText>}
      {subheaderText != null && <Typography>{subheaderText}</Typography>}
      {headerElement !== null && <Grid container>{headerElement}</Grid>}
      <Grid container>{childElements}</Grid>
    </Paper>
  );
};

export default SectionBody;
