import { SectionMain } from "layouts/ScreenLayout/sections/SectionMain";
import React, { useEffect, useState } from "react";
import ThisSessionHearder from "screens/Ecgscreens/ThisSessionHearder";
import {
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import SectionBody from "layouts/ScreenLayout/sections/SectionBody";
import EkgModal from "components/EkgModal";
import { getEKGAlgorithmDeterminationDisplayText, getEKGAlgorithmDeterminationStackFilter } from "constants/recordingMaps";
import Patientdetailsdata from "screens/Ecgscreens/Patientdetailsdata";
import SkeletonLoader from "components/Loading/SkeletonLoader";
import { dateFormatting, toPascalCaseString } from "utilities/Utilities";
import { useDispatch, useSelector } from "react-redux";
import TextInputs from "components/mui/formElements/TextInputs";
import { formatDate } from "utilities/Constants";

const ThisSessionEcg = ({
  ekgData,
  ecgData,
  state,
  handleChange,
  isAdminDisabled,
  crpReportId,
  deviceTypes
}) => {
  //Data comig from redux
  const patientData = useSelector((state) => state.ekgReview.patientInfo);
  const[reportIdValue, setReportIdValue]=useState()
  const[deviceTypesvalue, SetDeviceTypes] =useState()
  useEffect(()=>{
    if (crpReportId?.data) {
      if (ekgData?.id in crpReportId?.data?.byId) {
        setReportIdValue(crpReportId?.data?.byId[ekgData?.id]?.reportID);
      } else {
        setReportIdValue("--");
      }
    }
    if (deviceTypes?.data) {
      if (ekgData?.id in deviceTypes?.data?.byId) {
        SetDeviceTypes(deviceTypes?.data?.byId[ekgData?.id]?.reportID);
      } else {
        SetDeviceTypes("--");
      }
    }
  })

  const interpretation = getEKGAlgorithmDeterminationStackFilter(
    ekgData.algorithmDetermination,
    ekgData.algorithmPackage,
    ekgData.heartRate,
  );


  return (
    <SectionMain
      bodyElement={[
        {
          childElements: (
            <Grid xs={12}>
              <ThisSessionHearder
                userStatus={interpretation}
                devicedetails={deviceTypesvalue}
                heartrate={ekgData?.bpm}
                ekgData={ekgData}
              />
            </Grid>
          ),
        },
        {
          childElements: (
            <>
              <Grid item lg={6} md={6} sm={12} style={{ paddingRight: "16px" }}>
                <SectionBody
                  sx={{ backgroundColor: "#fff !important" }}
                  headerText={"ECG Details"}
                  childElements={
                    <Stack columnGap={0}>
                      <SkeletonLoader>
                        <Patientdetailsdata
                          labledata="AI Determination:"
                          keydata={
                            getEKGAlgorithmDeterminationDisplayText(
                              ekgData?.algorithmDetermination,
                              ekgData.algorithmPackage,
                              ekgData.bpm,
                            ) || "-"
                          }
                        />
                        <Patientdetailsdata
                          labledata="Report ID:"
                          keydata={reportIdValue && reportIdValue}
                        />
                        <Patientdetailsdata
                          labledata="Heart Rate:"
                          keydata={
                            ekgData?.bpm > 0 ? ekgData?.bpm + " bpm" : "--"
                          }
                        />
                        <Patientdetailsdata
                          labledata="Device:"
                          keydata={deviceTypesvalue && toPascalCaseString(deviceTypesvalue)}
                        />
                        <Patientdetailsdata
                          labledata="Date Recorded:"
                          keydata={`${formatDate(ekgData?.recordedAt)} ${dateFormatting(ekgData?.recordedAt)}`}
                        />
                      </SkeletonLoader>
                    </Stack>
                  }
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} style={{ paddingTop: "0px" }}>
                <SectionBody
                  sx={{ backgroundColor: "#fff !important" }}
                  childElements={
                    <>
                      <FormControl fullWidth rows={3}>
                        <InputLabel id="demo-simple-select-label">
                          Base Rythum
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          name="ekgInterpretationId"
                          value={state.ekgInterpretationId}
                          label="EKG Interpretation"
                          onChange={handleChange}
                          disabled={isAdminDisabled}
                          rows={3}
                        >
                          {state.ekgInterpretation.length > 0 &&
                            state.ekgInterpretation.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.heartCondition}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <br />
                      <TextInputs
                        label="EKG Interpretation Detail"
                        placeholder=""
                        onChange={handleChange}
                        multiline
                        rows={2}
                        disabled={isAdminDisabled}
                        name="ekgInterpretationText"
                        value={state.ekgInterpretationText}
                      />
                    </>
                  }
                />
              </Grid>
            </>
          ),
        },
      ]}
    />
  );
};

export default ThisSessionEcg;
