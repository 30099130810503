import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    customvarient: {
      color: 'var(--Light-Text-Secondary, rgba(0, 0, 0, 0.60))',
      fontFamily: 'Work Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px' /* 166.667% */,
      letterSpacing: '0.4px',
    },
  },
  Box: {
    boxvarient: {
      width: '368px',
      padding: '16px',
      borderRadius: '16px',
      border: '1px solid black',
      background: '#FFF',
      boxShadow:
        '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
    },
  },
});

export default theme;
