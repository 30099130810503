import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import {
  checkHasNumber,
  checkLowerCase,
  checkNumberOfCharacher,
  checkUpperCase,
} from 'utilities/Constants';
import { GoAPI } from 'Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import { authLogin } from 'store/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { handletoggleHeader } from 'store/actions';
import { headerText } from '../constant/Constant';
import TeamAlert from 'components/Shared/Alert/TeamAlert';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  '& .css-wb57ya-MuiFormControl-root-MuiTextField-root': {
    fontFamily: 'Work Sans',
    margin: '8px 0 !important',
  },
  '& .MuiTypography-body1': {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Work Sans',
    fontSize: '15px',
    fontWeight: 400,
  },
  '& .MuiTypography-body2': {
    // color: 'rgba(0, 0, 0, 0.38)',
    fontFamily: 'Work Sans',
    fontSize: '15px',
    fontWeight: 400,
  },
}));

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};

const validationSchema = yup.object().shape(
  {
    currentPassword: yup.string().required('Please enter Current password'),

    newPassword: yup
      .string()
      .required('Current password is required')
      .min(8, 'Password must have at least 8 characters')
      .max(20, 'Password must have at most 20 characters')
      .strict()
      .matches(/^\S*$/, 'Password must not contain any space')
      .matches(/[0-9]/, getCharacterValidationError('number'))
      .matches(/[a-z]/, getCharacterValidationError('lowercase'))
      .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
      .notOneOf([yup.ref('currentPassword'), null], 'same as current'),

    confirmPassword: yup
      .string()
      .required('Confirm password is required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  },
  // ['currentPassword'],
);

const UpdatePassword = () => {
  const dispatch = useDispatch();

  const [samePass, setSamePass] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [showAlert, setShowAlert] = React.useState({
    data: '',
    error: false,
  });
  const { email } = useSelector((state) => state.user.profile);

  useEffect(() => {
    dispatch(handletoggleHeader(headerText.TAB5, true));
  }, []);

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const updatePassword = (values) => {
    GoAPI.post(`i/v1/password`, values, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        if (res.status === 400) setSamePass(false);
        else {
          setSamePass(true);

          let credentials = {
            email: email,
            password: values.newPassword,
          };
          dispatch(authLogin(credentials));
        }
      })
      .catch((err) => {
        setShowAlert({
          data: 'You have entered wrong Current password',
          error: true,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 5000);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions, helpers) => {
        updatePassword(values);
        actions.setSubmitting(false);
        // helpers.resetForm({
        //   values,
        // });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => {
        return (
          <Form>
            <SectionMain
              headerElement={{
                headerText: 'Update Password',
                // helperText: '* Required',
              }}
              bodyElement={[
                {
                  childElements: (
                    <Root>
                      {showAlert.data !== '' && (
                        <TeamAlert title={showAlert.data} error={showAlert.error} />
                      )}
                      <Grid container>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            type={showPass ? 'text' : 'password'}
                            id="currentPassword"
                            name="currentPassword"
                            label="Current Password"
                            value={values.currentPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleClickShowPassword} edge="end">
                                      {!showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                  </InputAdornment>
                                </InputAdornment>
                              ),
                            }}
                            error={touched.currentPassword && Boolean(errors.currentPassword)}
                            helperText={touched.currentPassword && errors.currentPassword}
                          />
                          {!samePass && (
                            <Typography variant="subtitle2" color={'error'}>
                              Password does not match
                            </Typography>
                          )}

                          <TextField
                            fullWidth
                            type={showPass ? 'text' : 'password'}
                            id="newPassword"
                            name="newPassword"
                            label="New Password"
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleClickShowPassword} edge="end">
                                    {!showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            error={touched.newPassword && Boolean(errors.newPassword)}
                            helperText={touched.newPassword && errors.newPassword}
                          />
                          <TextField
                            fullWidth
                            type={showPass ? 'text' : 'password'}
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirm Password"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleClickShowPassword} edge="end">
                                      {!showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                  </InputAdornment>
                                </InputAdornment>
                              ),
                            }}
                            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                            helperText={touched.confirmPassword && errors.confirmPassword}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sx={{ margin: '8px 0' }}>
                          <Typography variant="body1">Your password must include:</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                          <div style={{ display: 'inline-flex' }}>
                            <TaskAltIcon
                              sx={{
                                color: checkLowerCase(values.newPassword)
                                  ? '#6387C5'
                                  : 'rgba(0,0,0,0.28)',
                              }}
                            />
                            <Typography
                              fullWidth
                              variant="body2"
                              sx={{
                                color: checkLowerCase(values.newPassword)
                                  ? '#6387C5'
                                  : 'rgba(0,0,0,0.28)',
                              }}
                            >
                              At least 1 lowercase letter
                            </Typography>
                          </div>
                          <div style={{ display: 'inline-flex' }}>
                            <TaskAltIcon
                              sx={{
                                color: checkHasNumber(values.newPassword)
                                  ? '#6387C5'
                                  : 'rgba(0,0,0,0.28)',
                              }}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                color: checkHasNumber(values.newPassword)
                                  ? '#6387C5'
                                  : 'rgba(0,0,0,0.28)',
                              }}
                            >
                              At least 1 number
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                          <div style={{ display: 'inline-flex' }}>
                            <TaskAltIcon
                              sx={{
                                color: checkNumberOfCharacher(values.newPassword)
                                  ? '#6387C5'
                                  : 'rgba(0,0,0,0.28)',
                              }}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                color: checkNumberOfCharacher(values.newPassword)
                                  ? '#6387C5'
                                  : 'rgba(0,0,0,0.28)',
                              }}
                            >
                              8-20 characters (no spaces)
                            </Typography>
                          </div>
                          <div style={{ display: 'inline-flex' }}>
                            <TaskAltIcon
                              sx={{
                                color: checkUpperCase(values.newPassword)
                                  ? '#6387C5'
                                  : 'rgba(0,0,0,0.28)',
                              }}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                color: checkUpperCase(values.newPassword)
                                  ? '#6387C5'
                                  : 'rgba(0,0,0,0.28)',
                              }}
                            >
                              At least 1 uppercase letter
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Root>
                  ),
                },
              ]}
              footerElement={{
                buttonInputProps: (
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      height: 'fit-content',
                      // backgroundColor: '#6387C5',
                    }}
                    disabled={errors.currentPassword || errors.newPassword}
                  >
                    Update Password
                  </Button>
                ),
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdatePassword;
