import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Document, Page, pdfjs } from 'react-pdf';

import EcgLoader from 'components/Shared/EcgLoader';
import classes from './ThisSessionStyles.module.css';
import { clinicianReviewPdf, getbphistorypdf } from 'Services/API/actions';
import { endPattern, pdfWidth } from './constant';
import { startDateForPDFSummaryReport } from './utility';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * @fetch pdf summary byte data from /summary-pdf api.
 * @returns reusable component which takes pfd byte data and converts it in pdf using Document class of react-pdf library before it renders pdf report of ecg, bp, weight summary for current month.
 */
function History({ startDateofAppointment, patientId,overreadreportId }) {
  const [numPages, setNumPages] = useState(null);
  const [pdfdata, setpdfdata] = useState(null);

  const { memberId } = useParams();

  const teamId = useSelector((state) => state.user.team.id);
  const clinicianId = useSelector((state) => state.user.profile.id);

  useEffect(() => {
    // This logic is written to achieve ASP-338 where date range should be prior to 30 days of schedule time to scheduled time.
    if (startDateofAppointment) {
      const summaryReportRequest = {
        //30 days past summary report to fetch
        startDateTime: startDateForPDFSummaryReport(startDateofAppointment, 30),
        endDateTime: moment(startDateofAppointment).format(endPattern),
        patientId: memberId,
      };
      clinicianReviewPdf(patientId, overreadreportId,summaryReportRequest).subscribe(
        (res) => {
          setpdfdata(res.data.result);
        },
        (error) => console.log(error),
      );
    }

    // setpdfdata(clinicianReviewPdf(patientId, overreadreportId))
  }, [startDateofAppointment]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <SectionMain
    headerElement={{
        headerText: `Clinician Review Report`,
        // subheaderText: `This is a 30 day summary of the member’s EKG and Blood Pressure.`,
      }}
      bodyElement={[
        {
          childElements: (
            <div style={{ maxHeight: '440px', overflow: 'auto', width: '100vw' }}>
              <Document
                // file={pdfdata}
                file={`data:application/pdf;base64,${pdfdata}`}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                  <div className={classes.pdfHistory}>
                    <EcgLoader />
                  </div>
                }
                noData={
                  <div className={classes.pdfHistory}>
                    <EcgLoader />
                  </div>
                }
                error={
                  <div className={classes.pdfHistory}>
                    <EcgLoader />
                  </div>
                }
              >
                {[...Array(numPages).keys()].map((p, i) => (
                  <Page key={i} pageNumber={p + 1} width={pdfWidth} />
                ))}
              </Document>
            </div>
          ),
        },
      ]}
    />
  );
}

export default History;
