import React from 'react'
import { Field, Form, Formik } from 'formik';
import { COMPONENT_USAGE } from '../constant/Constant';
import { CheckboxWithLabel, Select, Switch } from 'formik-material-ui';


const TeleconnectReviewComponent = ({ values, usage }) => {
  // This gives Teleconect Checbox on Setting page
  return (
    <Field
      type="checkbox"
      component={CheckboxWithLabel}
      name="access"
      key={'isTeleConnect'}
      value={'isTeleConnect'}
      Label={{ label: 'Teleconnect' }}
      disabled={
        !values.provider ||
        usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS
      }
    />
  )
}

export default TeleconnectReviewComponent
