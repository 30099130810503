export const HeaderDetails={
    HEADER_TEXT:'Session Details',
    DATE:'Date',
    TIME:'Time',
    METHOD_OF_SESSION:'Method of Session',
    CARDIOLOGIST_NAME:'Cardiologist Name',
    METHOD_OF_CONSULTATION:'Method of Consultation',
    CARDIOLOGIST:'Cardiologist',
    MEDICAL_HISTORY: 'Medical History',
    FAMILY_HISTORY:'Family History',
    MEDIACATIONS:'Medications',
    SOCIAL_HISTORY:'Social History',
    SURGICAL_HISTORY:'Surgical History',
    PATIENT_PROVIDED_DETAILS:'Patient Provided Details',
    REASON_FOR_CONSULTATION:'Reason for Consultation',
    SYMPTOMS:'Symptoms',
    NOTES_FOR_CARDIOLOGIST:'Notes for Cardiologist',
    SESSION_NOTES:'Provider  Notes',
    SESSION_DETAILS:'Write you personal notes here. They will not be shared with the patient.',
    REPORT_NOTES:'Report Documentation',
    REPORT_DETAILS:'Write your recommendations to the patient here.',
    BLODD_PRESSURE_HISTORY:'Blood Pressure 30 Days History'
}

export const Patient_Status={
    PATIENT_NO_SHOW:'Patient No Show',
    MEMBER_TECHNICAL_ISSUE  :'Member Technical Issue',
    PROVIDER_TECHNICAL_ISSUE :'Provider Technical Issue',
    MEMBER_DECLINED:'Member Declined',
    SYSTEM_CLOSED :' System Closed'
}

export const Call_Status={
    VIDEO_CALL:'Video Call',
    AUDIO_CALL  :'Audio Call',
    BACKUP_CALL :'Backup Call',
   OTHER:'Other',
   FOLLOW_UP:'Follow-up',
   NO_FOLLOW_UP:'No Follow-up',
   THREE_DAYS:'3 Days',
   ONE_WEEK:'1 Week',
   TWO_WEEK:'2 Weeks',
   ONE_MONTH:'1 Month'
}
