import * as actions from '../actions/actionTypes';

const initialState = {
  isFloatingWindow: false, // this state represents if any audio/phone session is ongoing.
  callStatus: '',
  noteId: '',
  isNote: true,
  appointmentId: null,
  memberId: null,
  isCallOver: false,
  memberType: '',
};

export function AdHocReducer(state = initialState, action) {
  switch (action.type) {
    case actions.START_PHONE_CALL:
      return {
        ...state,
        isFloatingWindow: true,
        callStatus: action.payload,
        memberId: action.member,
        appointmentId: action.appointmentId,
        isNote: action.isNote,
        memberType: action.memberType,
      };
    case actions.CLOSE_PHONE_CALL:
      return {
        ...state,
        isFloatingWindow: false,
        appointmentId: null,
        isCallOver: true,
      };
    case actions.CHANGE_CALL_STATUS:
      return {
        ...state,
        callStatus: action.payload,
      };
    case actions.NOTES_UPDATE:
      return {
        ...state,
        noteId: action.payload,
      };

    default:
      return state;
  }
}
