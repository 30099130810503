import { Typography } from '@material-ui/core';
import SkeletonLoader from 'components/Loading/SkeletonLoader';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import React from 'react'
import { Grid } from '@mui/material';
import moment from 'moment';
import { maplanguagevalue } from 'components/ekg/constants/EcgInboxConstants';

const DoctorThisSession = ({data}) => {
  return (
   <>
      <SectionMain
            headerElement={{
              headerText: `Session Details`,
            }}
            bodyElement={[
              {
                childElements: (
                  <>
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">Date</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">
                          {moment(data?.patientAppointment?.scheduledStartTime).format('ll') ||
                            '--'}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">Time</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">{`${moment(
                          data?.patientAppointment?.scheduledStartTime,
                        ).format('LT')} -
                          ${moment(data?.patientAppointment?.scheduledEndTime).format(
                          'LT',
                        )} (${moment(data?.patientAppointment?.scheduledEndTime).diff(
                          moment(data?.patientAppointment?.scheduledStartTime),
                          'minutes',
                        )} mins)`}</Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">Method of Consultation</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">
                          {data?.patientAppointment?.appointmentChannelType}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body2">Cardiologist</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">
                          {`${data?.patientAppointment?.physician?.lastName}, ${data?.patientAppointment?.physician?.firstName}`}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                  </>
                ),
              },
              {
                childElements: (
                  <>
                    <Grid item lg={4} md={6} xs={12} sm={12}>
                      <Typography variant="body2">Medical History</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">{data?.medicalHistory || '--'}</Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12} sm={12} style={{ padding: '0 12px' }}>
                      <Typography variant="body2">Family History</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">
                          {data?.familyMedicalHistory || '--'}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12} sm={12}>
                      <Typography variant="body2">Medications</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">{data?.medications || '--'}</Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12} sm={12}>
                      <Typography variant="body2">Social History</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">{data?.lifeStyleHistoryStr || '--'}</Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12} sm={12} style={{ padding: '0 12px' }}>
                      <Typography variant="body2">Surgical History</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">{data?.surgeryDetails || '--'}</Typography>
                      </SkeletonLoader>
                    </Grid>
                  </>
                ),
              },

            ]}
          />
          <SectionMain
            headerElement={{
              headerText: `Patient Provided Details`,
            }}
            bodyElement={[
              {
                childElements: (
                  <>
                    <Grid item md={6} lg={4}>
                      <Typography variant="body2">Reason for Consultation</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                         <Typography variant="body1">{data?.visitReason || '--'}</Typography>
                        <Typography variant="body1">{data?.patientAppointment?.visitReason || '--'}</Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item md={6} lg={4} style={{ padding: '0 12px' }}>
                      <Typography variant="body2">Symptoms</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        {data?.symptoms &&
                          data.symptoms.map((item, i) => {
                            return (
                              <li key={i}>
                                <span
                                  style={{
                                    fontFamily: 'Work Sans',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                  }}
                                >
                                  {item.name}, {item.severity}, {item.duration}
                                </span>
                              </li>
                            );
                          })}
                      </SkeletonLoader>
                    </Grid>
                    <Grid item md={6} lg={4}>
                      <Typography variant="body2">Notes for Cardiologist</Typography>
                      <SkeletonLoader
                        isloading={Object.keys(data).length === 0 && data?.patientAppointment}
                      >
                        <Typography variant="body1">
                          {data?.patientAppointment?.notesToCardiologist || '--'}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                    {/* Preffred language will be shown -ASP-533 */}
                    <Grid item md={6} lg={4}>
                      <Typography variant="body2">Language</Typography>
                        <Typography variant="body1">{maplanguagevalue(data?.patient?.locale) || '--'}</Typography>
                    </Grid>
                  </>
                ),
              },
            ]}
          />

   </>
  )
}

export default DoctorThisSession
