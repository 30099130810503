import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SettingsHeader from './SettingsHeader';
import SettingsSideNav from './SettingsSidebar';
import ScreenLayout from 'layouts/ScreenLayout';

import ProviderInformation from './ProviderInformation';
import Teammates from './Teammates';
import TeamSettings from './TeamSettings';
import NotificationSettings from './TeamSettings/NotificationSettings';
import UpdatePassword from './ProviderInformation/UpdatePassword';
import { handletoggleHeader } from 'store/actions';
import { COMPONENT_USAGE, headerText } from './constant/Constant';
import { GoAPI } from 'Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import { getregiondetails, regionListInformation } from 'store/actions/settings';

const Settings = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState('1');

  const userId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const { isAdmin } = useSelector((state) => state.user.profile.permissions);
  const { isSideBar } = useSelector((state) => state.settings);

  // const[countryDetails, setcountryDetails]=useState()
  // const getCountryDetails=(teamId)=>{
  //   GoAPI.get(`i/v1/teams/${teamId}/crRegions`, {
  //     headers: { Authorization: `Bearer ${getToken()}` },
  //   })
  //     .then((res) => {
  //       let data=res.data
  //       setcountryDetails(data)
  //       dispatch(regionListInformation(data))
  //     })
  //     .catch((err) => console.log(err));
  // }

useEffect(()=>{
  getregiondetails(teamId)
},[])
  const goBack = useCallback(() => {
    dispatch(handletoggleHeader(headerText.TAB2, true));
    // dispatch(regionListInformation(countryDetails))
    setActive('2');
  }, []);



  const getTab = useCallback(() => {
    switch (active) {
      case '1':
        return (
          <ProviderInformation
            userId={userId}
            teamId={teamId}
            usage={COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS}
            // getCountryDetails={getCountryDetails}
          />
        );
      case '2':
        return <Teammates goBack={goBack} />;
      case '3':
        return <TeamSettings />;
      case '4':
        return <NotificationSettings />;
      case '5':
        return <UpdatePassword />;
      case '6':
        return <ProviderInformation userId={userId} usage={COMPONENT_USAGE.USER_INVITE} />;
      default:
        return (
          <ProviderInformation
            userId={userId}
            teamId={teamId}
            usage={COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS}
          />
        );
    }
  }, [active]);

  return (
    <ScreenLayout
      header={<SettingsHeader goBack={goBack} />}
      aside={
        isSideBar ? (
          <SettingsSideNav isAdmin={isAdmin} activeTab={active} setActive={setActive} />
        ) : null
      }
    >
      {getTab()}
    </ScreenLayout>
  );
};

export default Settings;
