import React from 'react';
import { Box, Typography } from '@mui/material';
import { EMOJI_ECG_REVIEW } from 'assets/assetPath/constant';
import { EKG_COLOR } from 'screens/coach/MemberProfile/components/ThisSession/constant';

const Ecgstatus = ({ userStatus }) => {
  // Which renders the Images according to the userstatus /AI Determination
  const mapStatusData = (s) => {
    switch (s) {
      case 'Normal':
        return {
          emoji: EMOJI_ECG_REVIEW.SATISIFIED,
          border: '2px solid var(--Analysis-Normal, #6BC077)',
        };
      case 'No Analysis':
        return {
          emoji: EMOJI_ECG_REVIEW.NO_RESULT,
          border: `2px solid var(--Analysis-Normal, ${EKG_COLOR.NO_ANALYSIS})`,
        };
      case 'Other':
        return {
          emoji: EMOJI_ECG_REVIEW.NO_RESULT,
          border: `2px solid var(--Analysis-Normal, ${EKG_COLOR.NO_ANALYSIS})`,
        };
      case 'Abnormal':
        return {
          emoji: EMOJI_ECG_REVIEW.ABNORMAL,
          border: `2px solid var(--Analysis-Normal, ${EKG_COLOR.BRADYCARDIA})`,
        };
      case 'NeedsAttentation':
        return {
          emoji: EMOJI_ECG_REVIEW.NEED_ATTENTION,
          border: `2px solid var(--Analysis-Normal, ${EKG_COLOR.ATTENTATION})`,
        };
      case 'Sever':
        return {
           emoji: EMOJI_ECG_REVIEW.CRITICAL,
        border: `2px solid var(--Analysis-Normal, ${EKG_COLOR.SEVERE})`,
      };
      case 'Crisis':
        return {
          emoji: EMOJI_ECG_REVIEW.SEVERE,
          border: `2px solid var(--Analysis-Normal, ${EKG_COLOR.SEVERE})`,
        };
      default:
        return '';
    }
  };

  const { emoji, border } = mapStatusData(userStatus);

  return (
    <Box
      style={{
        display: 'flex',
        marginRight: '12px',
        borderRadius: '4px',
        border: border,
        padding: '0px 2px',
        alignItems: 'center',
        gap:'6px'
      }}
    >
      <Typography>
        <img style={{ height: '15px', marginTop: '3px' }} src={emoji} />
      </Typography>
      <Typography>{userStatus}</Typography>
    </Box>
  );
};

export default Ecgstatus;
