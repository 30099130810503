import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Grid, Box, Typography, styled } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Loader from 'components/Shared/EcgLoader';
import SectionBody from 'layouts/ScreenLayout/sections/SectionBody';

import { getMemberProfilePPDetails, setCurrentWeekDetails } from 'store/actions/memberProfile';

const Root = styled(Box)(({ theme }) => ({
  '& .MuiTypography-body2': {
    color: 'rgb(0, 0, 0, 0.6)',
    fontSize: '14px',
    fontFamily: 'Work Sans',
  },
}));
const ProgramProgress = () => {
  // const classes = programStyles();
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { loading, data, error } = useSelector((state) => state.memberProfile.programProgress);
  //local states
  const [ppData, setPpData] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(1);
  const [weekInProgress, setWeekInProgress] = useState(0);
  // programStartTime: "2022-01-08T14:53:12.597317Z"
  //7xqui976wb5yztlgj4mbu6usk
  useEffect(() => {
    dispatch(getMemberProfilePPDetails(1, memberId)); //memberId
  }, [dispatch, memberId]);
  useEffect(() => {
    weekInProgress > 0 && dispatch(getMemberProfilePPDetails(weekInProgress, memberId)); //memberId
  }, [weekInProgress, dispatch, memberId]);
  useEffect(() => {
    if (!loading && data) {
      setPpData(data);
      //setting up the current week from program time
      let start = new Date(data.programStartTime);
      let diff = moment.duration(moment().diff(moment(start)));
      let d = Math.floor(diff.asDays() / 7) + 1;
      if (weekInProgress === 0) {
        //setting current week in redux to use it in the other sections of the program tab
        dispatch(setCurrentWeekDetails(d));
        setCurrentWeek(d);
        setWeekInProgress(d);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);

  return (
    <Root style={{ minHeight: '300px' }}>
      <Box style={{ width: '100%' }}>
        {loading && (
          <Box style={{ marginBottom: '50px' }}>
            <Loader />
          </Box>
        )}
      </Box>

      {!loading && (
        <Box>
          <SectionBody
            sx={{
              margin: '-16px',
              width: 'auto',
              // border: 'unset !important',
              boxShadow: 'unset !important',
              background: 'transperent !important',
            }}
            headerText="Member Tasks"
            childElements={
              // <Grid container className={styles.tableRowHeading}>
              <>
                {!loading &&
                  ppData &&
                  ppData.tasks &&
                  ppData.tasks.map((p, idx) => (
                    <Grid item lg={4} md={6} key={idx}>
                      <Box style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 10 }}>
                        {currentWeek <= weekInProgress && (
                          <CheckCircleIcon
                            style={{
                              marginTop: '10px',
                              color: p.completedCount === p.totalCount ? '#6387C5' : '#F1F2F3',
                            }}
                          />
                        )}
                        <Box style={{ marginLeft: '18px' }}>
                          <Typography variant="body2">{p.Title}</Typography>
                          <Typography variant="body1">{p.SubTitle}</Typography>
                          {currentWeek <= weekInProgress && (
                            <Typography variant="body1">
                              {p.completedCount} of {p.totalCount} times this week
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  ))}

                {error ? (
                  <Typography variant="body1">Failed to fetch tasks</Typography>
                ) : (
                  ppData &&
                  !ppData.tasks && (
                    <Box
                      style={{
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Typography variant="body1">No data to display</Typography>
                    </Box>
                  )
                )}
              </>
            }
          />
          <div style={{ height: '46px' }} />
          <SectionBody
            sx={{
              margin: '-16px',
              width: 'auto',
              // border: 'unset !important',
              boxShadow: 'unset !important',
              background: 'transperent !important',
            }}
            headerText="Learn"
            childElements={
              <>
                {!loading &&
                  ppData &&
                  ppData.lessonPlans &&
                  ppData.lessonPlans.lessons.map((l, idx) => (
                    <Grid item lg={6} md={6} key={idx}>
                      <Box style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 10 }}>
                        {currentWeek <= weekInProgress && (
                          <CheckCircleIcon
                            style={{
                              marginTop: '10px',
                              color: l.isComplete ? '#6387C5' : '#F1F2F3',
                            }}
                          />
                        )}
                        <Box style={{ marginLeft: '18px' }}>
                          <Typography variant="body2">{l.name}</Typography>
                          <Typography variant="body1">{l.description}</Typography>
                          {currentWeek <= weekInProgress && (
                            <Typography variant="body1">
                              Completed: {l.isComplete ? 'Yes' : 'No'}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                {error ? (
                  <Typography variant="body1">Failed to fetch tasks</Typography>
                ) : (
                  ppData &&
                  !ppData.lessonPlans && (
                    <Box
                      style={{
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Typography variant="body1">No data to display</Typography>
                    </Box>
                  )
                )}
              </>
            }
          />
        </Box>
      )}
    </Root>
  );
};

export default ProgramProgress;
