import React from 'react'
import { Box, Button,Stack, Typography, FormGroup,FormControlLabel,Checkbox,} from '@mui/material';
import { CustomComponent, Lable, Value } from './Screens';

const Patientdetailsdata = ({labledata, keydata}) => {
  return (
    <Box sx={{ paddingBottom: '8px' }}>
    <CustomComponent text={labledata} variant={'body2lable'}/>
    <CustomComponent text={keydata} variant={'body2key'}/>
</Box>
  )
}

export default Patientdetailsdata
