export const BP_Page = {
    RECORDINGS: 'Recordings',
    MRNVALUE: 'MRN',
    DATEOFBIRTH: 'DOB',
    AGE: 'AGE',
    SEX: 'SEX',
    HEIGHT: 'HEIGHT',
    WEIGHT: 'WEIGHT',
    FIRST_DEGREE: '1st Degree',
    SECOND_DEGREE_ONE: '2nd Degree Mobitz I',
    THIRD_DEGREE: '3rd Degree',
    SECOND_DEGREE_TWO: '2nd Degree Mobitz II',
    NONE: 'None',
    FRENCH: 'French',
    SWEDISH: 'Swedish',
    ENGLISH: 'English',
    FINNISH: 'Finnish',
    VIETNAMESE: 'Vietnamese',
    IPREFERNOTTOSAY: 'I prefer not to say',
    M: 'MALE',
    F: 'FEMALE',
  };
  