import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material';

import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import { MuiTableComponent } from 'screens/common/CustomUI';
import ProviderInformation from '../ProviderInformation';

import { KccGoAPI } from 'Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import { getTimezoneName, parseName } from 'utilities/Utilities';
import { handletoggleHeader } from 'store/actions';
import { COMPONENT_POPUP_TEXTS, headerText, sectionText } from '../constant/Constant';
import ModalComponent from 'components/Common/modal/ModalComponent';
import { deleteTeammemberAPI } from 'Services/API/actions';

const Root = styled('div')(({ theme }) => ({
  '& .MuiDataGrid-root': {
    margin: 'unset !important',
    border: '1px solid lightgyey !important',
    borderRadius: '5px',
  },
}));
const Teammates = (props) => {
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Member',
      width: 280,
      editable: false,
      type: 'string',
      renderCell: (params) => (
        <p>
          {params?.row?.lastName}, {params?.row?.firstName}
        </p>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 320,
      // editable: false,
      type: 'string',
      renderCell: (params) => <p>{params?.row?.email}</p>,
    },
    {
      field: 'isAdmin',
      headerName: 'Admin',
      width: 100,
      editable: false,
      type: 'string',
      renderCell: (params) => <p>{params.row.permissions?.isAdmin ? 'Yes' : 'No'}</p>,
    },
    {
      field: 'isPhysician',
      headerName: 'Provider',
      width: 100,
      editable: false,
      type: 'string',
      renderCell: (params) => <p>{params.row.permissions?.isPhysician ? 'Yes' : 'No'}</p>,
    },
    {
      field: 'manageAvailability',
      headerName: 'Manage Availability',
      width: 200,
      editable: false,
      type: 'string',
      renderCell: (params) => <p>{params.row.canManageAvailability ? 'Yes' : 'No'}</p>,
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      sorting: false,
      // hide: true,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          key={1}
          // icon={<SecurityIcon />}
          label="Edit Profile"
          onClick={manageProfile(params.row)}
          // disabled={params.row.status === 3}
          showInMenu
        />,
        <GridActionsCellItem
          key={2}
          // icon={<SecurityIcon />}
          label="Delete Profile"
          onClick={deleteProfile(params.row)}
          disabled={params.row.id === profileId}
          showInMenu
        />,
      ],
    },
  ];

  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  const [filteredRow, setfilteredRow] = useState([]);
  const [showIndividualUser, setShowIndividualUser] = useState({
    userId: '',
    name: '',
    teamId: '',
    show: false,
  });
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [confirmDeleteTeammate, setConfirmDeleteTeammate] = useState(false);
  const [deleteTeammateId, setDeleteTeammateId] = useState('');

  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);
  const userType = useSelector((state) => state.user.chatProfile.userType);
  const { isSideBar } = useSelector((state) => state.settings);

  const getTeammateDetails = (teamId, userType) => {
    setloading(true);
    KccGoAPI.get(`i/v1/unified/teams/${teamId}/members?type=${userType}`)
      .then((res) => {
        let resultData = res.data.members || [];
        setfilteredRow(
          resultData.reduce((acc, cur) => {
            return [
              ...acc,
              {
                ...cur.member,
                name: `${cur.member.lastName}, ${cur.member.firstName}`,
                isAdmin: cur.member.permissions?.isAdmin ? 'Yes' : 'No',
                isPhysician: cur.member.permissions?.isPhysician ? 'Yes' : 'No',
                manageAvailability: cur.member.canManageAvailability ? 'Yes' : 'No',
              },
            ];
          }, []),
        );
        setloading(false);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const manageProfile = useCallback(
    (params) => () => {
      setShowIndividualUser({
        userId: params.id,
        name: parseName(params.firstName, params.lastName),
        show: true,
        teamId: params.teamID,
      });
    },
    [],
  );
  const deleteProfile = useCallback(
    (params) => () => {
      confirmDeleteTeammateClick(params.id);
    },
    [],
  );

  useEffect(() => {
    dispatch(handletoggleHeader(headerText.TAB2, true));
    getTeammateDetails(teamId, userType);
  }, []);

  useEffect(() => {
    isSideBar
      ? setShowIndividualUser((prev) => ({
          ...prev,
          show: false,
        }))
      : setShowIndividualUser((prev) => ({
          ...prev,
          show: true,
        }));
  }, [isSideBar]);

  const confirmDeleteTeammateClick = (id) => {
    setConfirmDeleteTeammate(true);
    setDeleteTeammateId(id);
  };
  const cancleDelete = () => {
    setConfirmDeleteTeammate(false);
  };
  const deleteTeammate = () => {
    const params = {
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
    };
    setConfirmDeleteTeammate(false);

    deleteTeammemberAPI(teamId, profileId, deleteTeammateId, params).subscribe(
      async (res) => {
        if ('detailed_error' in res.data) {
          console.log(res.data['detailed_error']);
        } else {
          res.status == 200 && getTeammateDetails(teamId, userType);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  return (
    <Root>
      {!showIndividualUser.show ? (
        <SectionMain
          headerElement={{
            headerText: `${sectionText.TEAM_MEMBER} List`,
          }}
          bodyElement={[
            {
              // headerText: 'Select the notification type the member will recieve for upcoming events.',
              childElements: (
                <MuiTableComponent
                  columns={columns}
                  rows={filteredRow}
                  loading={loading}
                  columnToolbar={true}
                  filterToolbar={true}
                  densityToolbar={true}
                  exportToolbar={false}
                  pagination
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  page={page}
                  onPageChange={(newPage) => setPage(newPage)}
                  rowsPerPageOptions={[20, 50, 100]}
                />
              ),
            },
          ]}
        />
      ) : (
        <ProviderInformation
          teamId={showIndividualUser.teamId}
          userId={showIndividualUser.userId}
          usage={showIndividualUser.name}
          goBack={() => props.goBack()}
          reloadMemberData={() => getTeammateDetails(teamId, userType)}
        />
      )}
      <ModalComponent
        headerText={'Delete Member?'}
        modalText={COMPONENT_POPUP_TEXTS.DELETE_PROVIDER}
        subText={COMPONENT_POPUP_TEXTS.DELETE_PROVIDER_SUBTEXT}
        open={confirmDeleteTeammate}
        handleClose={cancleDelete}
        cancelButtonText={'cancel'}
        confirmButtonText={'Yes, Delete'}
        confirmHandler={deleteTeammate}
        // confirmPayload={confirmRevokeLicenseParameter}
      />
    </Root>
  );
};

export default Teammates;
