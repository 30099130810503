import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  Box,
  Button,
  FormControl,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import moment from 'moment';

import Loading from 'components/Shared/EcgLoader';
import PadIcon from 'assets/images/icons/profile/pcp.svg';
import { getPCPMetadata, updatePCPData } from 'store/actions/memberProfile';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';

const ITEM_TYPES = {
  SINGLE_SELECT: 'SingleSelect',
  SINGLE_LINE: 'SingleLine',
  MULTI_LINE: 'MultiLine',
};
const HealthDetailsPCP = ({ classes }) => {
  const { memberId } = useParams();
  const dispatch = useDispatch();
  const [pcpEdit, setPcpEdit] = useState(false);
  const [metaData, setMetaData] = useState([]);
  const [pcpAnswers, setPcpAnswers] = useState([]);

  const { metaData: metaDataFromStore, answers } = useSelector((state) => state.memberProfile.pcp);

  useEffect(() => {
    dispatch(getPCPMetadata(memberId));
  }, [memberId]);

  useEffect(() => {
    if (!metaDataFromStore.loading && metaDataFromStore.data) {
      let cells = [];
      let itemsToPush = null;

      metaDataFromStore.data.map((cell, index) => {
        let temp = [];
        cell.items.map((d) => {
          var { mandatory, key, type, hint, title } = d;

          switch (d.type) {
            case ITEM_TYPES.SINGLE_SELECT:
              let { labels, values } = d;
              itemsToPush = { mandatory, key, title, labels, values, type };
              break;
            case ITEM_TYPES.SINGLE_LINE:
              itemsToPush = { mandatory, key, type, hint, title };
              break;
            case ITEM_TYPES.MULTI_LINE:
              let { rowLimit } = d;
              itemsToPush = { mandatory, key, type, hint, title, rowLimit };
              break;
          }
          if (itemsToPush) {
            //answer

            temp.push(itemsToPush);
            itemsToPush = null;
          }
        });
        cells.push(temp);
      });

      if (cells.length > 0) {
        setMetaData(cells);
      }
    }
  }, [metaDataFromStore.loading, metaDataFromStore.data, answers.data]);

  useEffect(() => {
    if (!answers.loading && answers.data && answers.data.length > 0) {
      setPcpAnswers(answers.data);
    }
  }, [answers.loading, answers.data]);

  const onValueChange = (key, val, outIndex, inIndex) => {
    setPcpAnswers((c) => {
      if (c.length > outIndex) c[outIndex][key] = val;
      else c.push({ [key]: val });
      return [...c];
    });
  };
  const onSubmit = () => {
    setPcpEdit((c) => !c);
    dispatch(updatePCPData('providerTable', memberId, { data: pcpAnswers }));
  };

  return (
    <>
      <SectionMain
        headerElement={{
          headerText: 'Care Provider Information',
          helperText: (
            <Typography variant="subtitle1" style={{ marginRight: 25 }}>
              Last updated:{' '}
              {answers?.data?.updatedTime
                ? moment(answers?.data?.updatedTime).format('MM-DD-YYYY')
                : '-'}{' '}
              <PadIcon />
            </Typography>
          ),
        }}
        bodyElement={[
          {
            childElements: (
              <Grid container spacing={1} sx={{ rowGap: '10px' }}>
                {metaDataFromStore.loading && (
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    {metaDataFromStore.loading && <Loading />}
                  </Grid>
                )}
                {!metaDataFromStore.loading &&
                  metaData.map((d, outIndex) => (
                    <React.Fragment key={outIndex}>
                      {pcpEdit && (
                        <Grid item xs={6} key={outIndex} style={{ marginTop: 0, paddingTop: 10 }}>
                          <Box>
                            <Typography variant="subtitle1" style={{ marginTop: '10px' }}>
                              Provider {outIndex + 1}: Information
                            </Typography>
                            {d.map((item, inIndex) => {
                              if (item.type === ITEM_TYPES.SINGLE_LINE) {
                                return (
                                  <TextField
                                    sx={{ margin: '8px 0' }}
                                    key={inIndex}
                                    fullWidth
                                    label={item?.title.split(' ').splice(1).join('  ')}
                                    placeholder={`Edit Provider ${item?.title
                                      .split(' ')
                                      .splice(1)
                                      .join('  ')}`}
                                    showIcon={true}
                                    icon={
                                      item?.key === 'providerEmail' ? (
                                        <EmailIcon />
                                      ) : item?.key === 'providerPhoneNumber' ? (
                                        <CallIcon />
                                      ) : null
                                    }
                                    value={
                                      pcpAnswers.length > 0 ? pcpAnswers[outIndex][item.key] : ''
                                    }
                                    onChange={(e) => {
                                      onValueChange(item.key, e.target.value, outIndex, inIndex);
                                    }}
                                  />
                                );
                              } else if (item.type === ITEM_TYPES.MULTI_LINE) {
                                return (
                                  <TextField
                                    sx={{ margin: '8px 0' }}
                                    key={inIndex}
                                    fullWidth
                                    label={item?.title.split(' ').splice(1).join('  ')}
                                    placeholder={`Edit Provider ${item?.title
                                      .split(' ')
                                      .splice(1)
                                      .join('  ')}`}
                                    showIcon={true}
                                    icon={
                                      item?.key === 'providerEmail' ? (
                                        <EmailIcon />
                                      ) : item?.key === 'providerPhoneNumber' ? (
                                        <CallIcon />
                                      ) : null
                                    }
                                    value={
                                      pcpAnswers.length > 0 ? pcpAnswers[outIndex][item.key] : ''
                                    }
                                    onChange={(e) => {
                                      onValueChange(item.key, e.target.value, outIndex, inIndex);
                                    }}
                                    multiline
                                    rows={2}
                                  />
                                );
                              } else if (item.type === ITEM_TYPES.SINGLE_SELECT) {
                                return (
                                  <FormControl fullWidth key={inIndex} sx={{ margin: '8px 0' }}>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      label="Type"
                                      placeholder="Edit Provider type"
                                      value={
                                        pcpAnswers.length > 0 ? pcpAnswers[outIndex][item.key] : ''
                                      }
                                      onChange={(e) => {
                                        onValueChange(item.key, e.target.value, outIndex, inIndex);
                                      }}
                                    >
                                      <MenuItem value="">Select</MenuItem>
                                      {item.labels.map((l, index) => (
                                        <MenuItem key={inIndex} value={item.values[index]}>
                                          {l}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </Box>
                        </Grid>
                      )}
                      {!pcpEdit && (
                        <Grid item xs={6} key={outIndex}>
                          <Typography variant="subtitle1">
                            Provider {outIndex + 1}: Information
                          </Typography>

                          {d.map((item, i) => (
                            <Grid container key={i} spacing={1}>
                              <Grid item xs={3}>
                                <Typography variant="body2">
                                  {item?.title.split(' ').splice(1).join('  ')}
                                </Typography>
                              </Grid>
                              <Grid item xs={9} style={{ marginTop: '10px' }}>
                                <Typography key={i} variant="body1">
                                  {':  '}
                                  {pcpAnswers[outIndex][item.key]
                                    ? pcpAnswers[outIndex][item.key]
                                    : ''}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </React.Fragment>
                  ))}
              </Grid>
            ),
          },
        ]}
        footerElement={{
          buttonInputProps: (
            <Button
              variant="outlined"
              onClick={() => (!pcpEdit ? setPcpEdit((c) => !c) : onSubmit())}
            >
              {pcpEdit ? 'Update' : 'Edit'} Provider Information
            </Button>
          ),
        }}
      />
    </>
  );
};

export default HealthDetailsPCP;
