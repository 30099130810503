import React from 'react'
import {Typography} from "@mui/material";
import { toPascalCaseString } from 'utilities/Utilities';

const Ecgdevicedata = ({devicedetails}) => {
  // This will display the Decive information when the device data is present
  return (
    <>
    {devicedetails ?
    <Typography
    style={{
      marginRight: "19px",
      borderRadius: "4px",
      border: "2px solid var(--light-other-outlined-border-23-p, rgba(0, 0, 0, 0.23))",
      padding: "2px 2px",
      alignItems: "center",
    }}
  >
    {toPascalCaseString(devicedetails)}
  </Typography>: null
    }
    </>
    
  )
}

export default Ecgdevicedata
