import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { styled } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import {
  Collapse,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AttachmentIcon from '@mui/icons-material/Attachment';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { fontSizes, colors, fonts } from '../../../../../style/styleSettings';
import { EnhancedTableHead } from '../../../Common/EnhancedTableHead';
import ViewNotes from 'screens/coach/Common/ViewNotes';
import ReportModal from 'components/DoctorAppointments/ReportModal';
import Documentation from 'components/Common/ModalView/Documentation';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';

import { TeleKardiaAPI } from '../../../../../Axios/axios';
import { getTimezoneName } from 'utilities/Utilities';
import { getToken } from 'auth/AuthUtilities';
import EcgLoader from 'components/Shared/EcgLoader';
import {
  getMemberProfileSessionLogData,
  getNote,
  getNotesMetadata,
  setDocumentationFilterAction,
} from 'store/actions/memberProfile';
import { filterVariableMap, tableHead } from './data';
import Filter from './Filter';
import { ADHOC_SMS, ALERT_NOTE, backendNotesType, SURVEY } from 'constants/app';

const Root = styled('div')(({ theme }) => ({
  minHeight: '100px',
  fontFamily: fonts.SESSION,
  marginTop: '20px',
  color: colors.SESSION_BLACK,
  '& .MuiButton-label': {
    fontFamily: 'Work Sans',
  },
  '& .MuiTableCell-head': {
    fontWeight: 'bold !important',
    fontSize: fontSizes.BASE,
  },
  '& .MuiTypography-body1': {
    fontSize: fontSizes.BASE,
  },
  '& .MuiTableBody-root': {
    '&:nth-of-type(4n+1)': {
      background: 'rgb(250, 250, 250) !important',
    },
  },
  '& .MuiTableCell-body': {
    fontSize: fontSizes.BASE,
    fontFamily: 'Work Sans',
  },

  '& .MuiChip-outlinedPrimary': {
    color: 'black',
    border: `1px solid ${colors.SPRUCE}`,
  },
  '& .MuiChip-outlinedSecondary': {
    color: 'black',
    border: `1px solid ${colors.SESSION_CHIP_OUTLINE_RED}`,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function Row(props) {
  const { row, memberId } = props;
  const [open, setOpen] = React.useState(false);
  const [openReport, setopenReport] = useState(false);
  const [sessionNotesData, setsessionNotesData] = useState(null);
  const [programFocusData, setprogramFocusData] = useState([]);
  const [viewHHData, setViewHHData] = useState([]);
  const [hhDataDeletionNotes, sethhDataDeletionNotes] = useState('');
  const [loading, setloading] = useState(false);
  const teamId = useSelector((state) => state.user.team.id);
  const coachId = useSelector((state) => state.user.profile.id);
  const offsetInMinutes = new Date().getTimezoneOffset();
  const timeZone = getTimezoneName();

  const viewNotes = () => {
    if (
      row.physicianSession &&
      row.type !== backendNotesType.ADHOC_NOTES &&
      row.type !== backendNotesType.ADHOC_CALL &&
      row.type !== ALERT_NOTE &&
      row.type !== SURVEY &&
      row.type !== backendNotesType.ADHOC_SMS
    ) {
      // for cardiology session on click on view notes the report should show up
      setopenReport(true);
    } else if (
      row.type === backendNotesType.HEART_HABIT ||
      row.type === backendNotesType.PROGRAM_FOCUS
    ) {
      setloading(true);
      getNote(teamId, coachId, row.id)
        .then((res) => {
          setprogramFocusData(res.result?.extras?.programFocus);
          setViewHHData(res.result?.extras?.heartHabit);
          sethhDataDeletionNotes(res.result?.description);
          setloading(false);
        })
        .catch((err) => console.log(err));
      setOpen(!open);
    } else if (
      row.type == backendNotesType.ADHOC_NOTES ||
      row.type == backendNotesType.ADHOC_CALL ||
      row.type == ALERT_NOTE ||
      row.type == SURVEY ||
      row.type === backendNotesType.ADHOC_SMS
    ) {
      props.setopenViewNotesModal(!props.openViewNotesModal);
    } else {
      setloading(true);
      //api call to receive session notes, program focus and heart habit.
      TeleKardiaAPI.get(
        `/api/v1/teams/${teamId}/clinicians/${coachId}/patients/${memberId}/appointments/${row.id}/detail?offsetInMinutes=${offsetInMinutes}&timeZone=${timeZone}`,
        {
          headers: { Authorization: ` Bearer ${getToken()}` },
        },
      )
        .then((res) => {
          const data = res.data.result;
          setsessionNotesData(data?.note);
          setloading(false);
        })

        .catch((err) => console.log(err));
      setOpen(!open); //toggle the accordion based on click of "view notes"
    }
  };
  const reportmodalCloseHandler = () => {
    setopenReport(false);
  };
  return (
    <React.Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          {moment(row.dateTime).format('MM-DD-yyyy')}
        </TableCell>
        <TableCell>{moment(row.dateTime).format('hh:mm A')}</TableCell>
        <TableCell>
          <div
            style={{
              border: row.status == 'Draft' ? `2px solid ${colors.SPRUCE}` : `2px solid black`,
              borderRadius: '100px',
              padding: '8px 12px 8px 12px',
              width: 'fit-content',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontWeight: 600,
                textTransform: 'uppercase',
                fontSize: 11,
                letterSpacing: '1.5px',
                lineHeight: '13px',
              }}
            >
              {row.status}
            </Typography>
          </div>
        </TableCell>
        {/* <TableCell>
          {moment(row.scheduledEndTime || row.enddatetime).diff(
            moment(row.scheduledStartTime || row.startdatetime),
            'minutes',
          )}{' '}
          mins
        </TableCell> */}
        <TableCell
          style={{
            textAlign: 'right',
          }}
        >
          {row.attachment ? <AttachmentIcon /> : '-'}
        </TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{row.tag || '-'}</TableCell>
        <TableCell
          style={{
            color: colors.SPRUCE,
            fontWeight: 'bold',
            fontSize: `${fontSizes.CAPTION} !important`,
            cursor: 'pointer',
          }}
          onClick={viewNotes}
        >
          {row.appointmentStatus === 'Scheduled' || row.appointmentStatus === 'InProgress' ? (
            '-- --'
          ) : (
            <>
              {open ? 'HIDE NOTES' : 'VIEW NOTES'}
              <IconButton aria-label="expand row" size="small">
                {open ? (
                  <KeyboardArrowUpIcon style={{ color: colors.SPRUCE }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ color: colors.SPRUCE }} />
                )}
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {!!loading ? (
              <EcgLoader />
            ) : (
              <ViewNotes
                sessionNotes={sessionNotesData}
                programFocus={programFocusData}
                heartHabit={viewHHData}
                hhDataDeletionNotes={hhDataDeletionNotes}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      {openReport && ( //Report modal is for cardiology consultations.
        <ReportModal
          key="report-modal"
          appointmentId={row.id}
          patientId={memberId}
          memberId={coachId}
          show={openReport}
          handleClose={reportmodalCloseHandler}
        ></ReportModal>
      )}
    </React.Fragment>
  );
}

function ActivityAndNotes(props) {
  const { memberId } = useParams();
  const dispatch = useDispatch();
  const [sessionLogsDetails, setsessionLogsDetails] = useState([]);
  const [openNotesModal, setopenNotesModal] = useState(false);
  const sessionCredits = useSelector((state) => state.memberProfile?.programData);
  const teamId = useSelector((state) => state.user.team.id);
  const coachId = useSelector((state) => state.user.profile.id);
  const isAdmin = useSelector((state) => state.user.profile.permissions?.isAdmin);
  const { filters, data } = useSelector((state) => state.memberProfile.sessionLogs);
  const filterMetaData = useSelector((state) => state.memberProfile.sessionLogs.metaData);
  const noteMetaData = useSelector((state) => state.memberProfile.noteMetaData);

  const [loading, setloading] = useState(false);
  const [openedNoteId, setOpenedNoteId] = useState(null);

  useEffect(() => {
    if (openedNoteId == null && openNotesModal == false) {
      setloading(true);
      dispatch(getMemberProfileSessionLogData(memberId, ''));
    }
  }, [dispatch, memberId, openedNoteId, openNotesModal]);

  useEffect(() => {
    dispatch(getNotesMetadata(teamId, coachId, true, SURVEY)); //notesmetadata
    dispatch(getNotesMetadata(teamId, coachId, false, SURVEY));
  }, []);

  useEffect(() => {
    if (data) {
      setloading(false);
      setsessionLogsDetails(data);
    }
  }, [data]);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('scheduledStartTime' || 'startdatetime');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openNotes = () => {
    setopenNotesModal(!openNotesModal);
  };
  //to change the current note id
  const currentNoteManager = (id) => {
    setOpenedNoteId(id);
    openNotes();
  };

  //stores filters and refetches
  const filterAndRefetch = (data) => {
    const filterString = _parseFilters(data);
    dispatch(getMemberProfileSessionLogData(memberId, filterString));
    return saveFilters(data);
  };

  //store filters in redux
  const saveFilters = (data) => dispatch(setDocumentationFilterAction(data));

  //used internally
  const _parseFilters = (data) => {
    if (data) {
      //avoid 'all' flags
      //using an array as it facilitates the join operation
      let filterData = Object.values(data);
      let filterArray = [];
      Object.keys(filterVariableMap).map((f) => {
        let currentFilterCodes = [];
        filterData.map((item) => {
          if (item.checked && item.type == f && !item.id.includes('all')) {
            currentFilterCodes.push(item.code);
          }
        });
        if (currentFilterCodes.length > 0) {
          filterArray.push(`${f}=${currentFilterCodes.join(',')}`);
        }
      });
      return filterArray.join('&');
    }
  };

  const filterSeedData = (metaData) => {
    let disableWith = 'type';
    let initData = {};

    if (metaData) {
      const typeList = Object.keys(metaData[disableWith]);
      Object.entries(filterVariableMap).map((d) => {
        initData[`all-${d[0]}`] = {
          id: `all-${d[0]}`,
          name: `All`,
          checked: true,
          type: `${d[0]}`,
          code: metaData[d[0]] ? Object.keys(metaData[d[0]]).join(',') : null,
          disabled: false,
          enableForType: null,
        };
      });
      for (let i = 0; i < Object.keys(metaData).length; i++) {
        let currentFilter = Object.keys(metaData)[i];
        let currentFilterValues = Object.values(metaData)[i];

        for (let j = 0; j < Object.values(currentFilterValues).length; j++) {
          //need on the fly id ${currentFilter}${j+1}
          let enableForType = null;
          switch (currentFilter) {
            case 'type':
              break;
            case 'tag': //if tag then we enable it only for adhoc call and adhoc note
              enableForType =
                Object.keys(currentFilterValues)[j] > 5
                  ? [typeList[3]]
                  : [typeList[0], typeList[1], typeList[2], typeList[4], typeList[5]];
              break;
            case 'attachment': //if attachment then we enable it only for adhoc call and adhoc note
              enableForType = [typeList[0], typeList[1], typeList[2], typeList[3]];
              break;
            case 'status': //if status> 100 (Draft = 101, signed=102) then we enable it only for adhoc call and adhoc note or else for session
              enableForType =
                Object.keys(currentFilterValues)[j] > 100
                  ? [typeList[0], typeList[1], typeList[2], typeList[3], typeList[4], typeList[5]]
                  : [typeList[6], typeList[7]];
              break;
            default:
              break;
          }

          initData[`${currentFilter}${j}`] = {
            name: Object.values(currentFilterValues)[j],
            id: `${currentFilter}${j}`,
            checked: true,
            type: currentFilter,
            code: Object.keys(currentFilterValues)[j],
            disabled: false,
            enableForType: enableForType,
          };
        }
      }
    }
    return initData;
  };
  //function to merge key and values of an object from an array
  function toObject(names, values) {
    var result = {};
    for (var i = 0; i < names.length; i++) result[names[i]] = values[i];
    return result;
  }

  //function to find intersection of two arrays
  function matches(arr1, arr2) {
    const intersection = arr1.filter((element) => arr2.includes(element));
    return intersection.length > 0;
  }

  const disableCriterion = (data, value) => {
    let filterData = Object.values(data);
    let typeName = 'type';
    let happyEnableTypeArray = ['1', '2', '3', '4', '5', '6', '1001'];
    let enableTypeArray = [];
    filterData.map((f) => {
      if (f.type == typeName && !f.id.includes('all')) {
        if (f.checked && f.code) enableTypeArray.push(f.code);
      }
    });
    filterData.map((f) => {
      if (f.type != typeName && f.enableForType != null) {
        if (matches(enableTypeArray, f.enableForType)) f.disabled = false;
        else {
          f.disabled = true;
          f.checked = false;
          //remove "all" checked
          data[`all-${f.type}`].checked = false;
        }
      }
    });
    if (enableTypeArray !== happyEnableTypeArray) {
      filterData.map((f) => {
        if (value.id.includes('all')) {
          if (f.type === value.type) f.checked = false;
        }
        // if(value.id.includes('all'))
        if (f.id.includes('all')) {
          if (f.type === value.type) f.checked = false;
          else f.checked = true;
        }
      });
    }

    return toObject(Object.keys(data), filterData);
  };
  return (
    <>
      <Root>
        {loading ? (
          <EcgLoader />
        ) : (
          <>
            {/* filter and add note section  */}
            <SectionMain
              headerElement={{
                headerText: `Documentation`,
                action: (
                  <Button variant="contained" onClick={openNotes}>
                    Add Note
                  </Button>
                ),
              }}
              bodyElement={[
                {
                  childElements: (
                    <>
                      <Filter
                        filterVariableMap={filterVariableMap}
                        filterMetaData={filterMetaData}
                        filterSeedData={filterSeedData}
                        filterAndRefetch={filterAndRefetch}
                        filtersOnChange={(data, value) => disableCriterion(data, value)}
                        saveFilters={saveFilters}
                      />

                      <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                          <EnhancedTableHead
                            from="sessionLogs"
                            columnData={tableHead}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                          />
                          <TableBody>
                            {sessionLogsDetails &&
                              sessionLogsDetails.length > 0 &&
                              stableSort(sessionLogsDetails, getComparator(order, orderBy)).map(
                                (row, index) => (
                                  <Row
                                    key={index}
                                    row={row}
                                    memberId={memberId}
                                    openNotesModal={openNotesModal}
                                    setopenViewNotesModal={() => currentNoteManager(row.id)}
                                  />
                                ),
                              )}
                          </TableBody>
                        </Table>
                        {!sessionLogsDetails ||
                          (sessionLogsDetails.length == 0 && (
                            <Typography
                              variant="h6"
                              style={{
                                textAlign: 'center',
                                paddingTop: '30px',
                                fontFamily: 'Work Sans',
                                margin: '20px',
                              }}
                            >
                              No data available
                            </Typography>
                          ))}
                      </TableContainer>
                    </>
                  ),
                },
              ]}
            />
          </>
        )}
      </Root>
      {openNotesModal && (
        <Documentation
          onClose={() => {
            currentNoteManager(null);
          }}
          noteId={openedNoteId}
          setopenNotesModal={setopenNotesModal}
          memberId={memberId}
          openNotesModal={openNotesModal}
        />
      )}
    </>
  );
}

export default ActivityAndNotes;
