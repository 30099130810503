import { Base64 } from 'js-base64';

// import { AUTH_TYPES, getRegion } from 'constants'
import { checkAuthentication, getToken } from 'auth/AuthUtilities';
// import { GoAPI } from '../Axios/axios';
import { generateQueryParam } from 'components/Utilities';
import { dataSourceSuccess, setApiServiceError } from 'store/reducers/app';
import { KccGoAPI, KproService } from '../Axios/axios';
import config from '../setup';
import { DATASOURCES, getRegion } from 'constants/app';
import { displayValidationError } from 'components/TeamMemberInvite/module';
// eslint-disable-next-line consistent-return

export const AUTH_TYPES = {
  BASIC: 'BASIC',
  NONE: 'NONE',
  BEARER: 'BEARER',
};

export const getApiBaseUrl = () => {
  let region = 'us';
  region = region !== '' ? `${region}-` : '';
  return `${process.env.REACT_APP_API_PROTOCOL}://${region}${process.env.REACT_APP_API_DOMAIN}`;
};

export const fetchApi =
  ({
    url = '',
    method = 'GET',
    body,
    header = {},
    authType = AUTH_TYPES.NONE,
    credentials = {}, // required for basic auth
  }) =>
  (dispatch, getState) => {
    const { browser } = getState().app;

    const headers = new Headers(
      Object.assign(
        {},
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        header,
      ),
    );

    const { BEARER, BASIC, NONE } = AUTH_TYPES;
    let basicToken;
    const { username = '', password = '' } = credentials;

    switch (authType) {
      case BASIC:
        basicToken = Base64.encode(`${username}:${password}`) || '';
        headers.append('Authorization', `Basic ${basicToken}`);
        break;
      case BEARER:
        headers.append('Authorization', `Bearer ${getToken() || ''}`);
        break;
      case NONE:
      default:
        break;
    }

    if (browser && browser.name === 'ie') {
      headers.append('pragma', 'no-cache');
      headers.append('cache-control', 'no-cache, no-store, must-revalidate, max-age=0');
    }

    const init = {
      method,
      headers,
      mode: 'cors',
    };

    if (body) {
      init.body = body;
    }

    return fetch(url, init)
      .then((res) => {
        if (res.status === 401) {
          dispatch(checkAuthentication());
          throw new Error('Unauthorized');
        } else if (res.status === 503) {
          dispatch(
            setApiServiceError({
              status: res.status,
              url,
            }),
          );
        }
        // if (url !== `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/jwt`) {
        //   // TODO: Potentially need to throttle
        //   if (getState().auth.isAuthenticated
        //     && timeRemainingOnTokenMs() > 0
        //     && timeRemainingOnTokenMs() <= NETWORK_RENEW_AUTH_THRESHOLD_MS) {
        //     dispatch(renewAuth()) // Refresh auth every valid response
        //   }
        // }
        return res;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        throw err;
      });
  };

export const fetchMe = () => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/me`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchPostResetPasswordToken = (token, password) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/reset_password/${token}`,
      method: 'POST',
      body: JSON.stringify(password),
    }),
  )
    // .then(res => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchPostResetPassword = (email) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/reset_password`,
      method: 'POST',
      body: JSON.stringify(email),
    }),
  )
    // .then(res => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchPhysiciansAndEkgStackCount = (teamId, userType) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KCC_GOKARDIA_API}i/v1/teams/${teamId}/members?type=${userType}`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchTeamTriggers = (teamId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/triggers`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchPutTeamTriggers = (teamId, triggers) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/triggers`,
      authType: AUTH_TYPES.BEARER,
      method: 'PUT',
      body: JSON.stringify(triggers),
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchPostTeamMemberInvite = (teamId, newTeamMember) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KCC_GOKARDIA_API}i/v1/kcc/invite`,
      authType: AUTH_TYPES.BEARER,
      method: 'POST',
      body: JSON.stringify(newTeamMember),
    }),
  )
    // .then(res => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchDeleteTeamMemberInvite = (inviteId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/invites/${inviteId}`,
      authType: AUTH_TYPES.BEARER,
      method: 'DELETE',
    }),
  )
    // .then(res => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchGetTeamMember = (teamId, teamMemberId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KCC_GOKARDIA_API}i/v1/kcc/teams/${teamId}/members/${teamMemberId}`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchGetTeamMemberInvites = (teamId, userType) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KCC_GOKARDIA_API}i/v1/teams/${teamId}/invites?userType=${userType}`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchPostPassword = (password) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/password`,
      authType: AUTH_TYPES.BEARER,
      method: 'POST',
      body: JSON.stringify(password),
    }),
  ).catch((err) => {
    throw err;
  });

export const fetchPutTeamMember = (teamId, teamMemberId, teamMember) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KCC_GOKARDIA_API}i/v1/teams/${teamId}/members/${teamMemberId}`,
      authType: AUTH_TYPES.BEARER,
      method: 'PUT',
      body: JSON.stringify(teamMember),
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchPutTeamMemberReminders =
  (teamId, teamMemberId, reminders) => (dispatch, getState) =>
    dispatch(
      fetchApi({
        url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/members/${teamMemberId}/reminders`,
        authType: AUTH_TYPES.BEARER,
        method: 'PUT',
        body: JSON.stringify(reminders),
      }),
    )
      .then((res) => res.json())
      .catch((err) => {
        throw err;
      });
export const fetchDeleteTeamMember = (teamId, teamMemberId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KCC_GOKARDIA_API}i/v1/teams/${teamId}/members/${teamMemberId}`,
      authType: AUTH_TYPES.BEARER,
      method: 'DELETE',
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchPostAppointmentNotification = (teamId, memeberId, data) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${config.TeleKardiaBaseUrl}api/v1/teams/${teamId}/member/${memeberId}/settings/notification`,
      authType: AUTH_TYPES.BEARER,
      method: 'POST',
      body: JSON.stringify(data),
    }),
  ).catch((err) => {
    throw err;
  });
export const fetchAppointmentNotification = (teamId, memeberId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${config.TeleKardiaBaseUrl}api/v1/teams/${teamId}/member/${memeberId}/settings/notification`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchPutTeamMemberInfo = (teamId, teamMemberId, teamMember) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v2/teams/${teamId}/members/${teamMemberId}`,
      authType: AUTH_TYPES.BEARER,
      method: 'PUT',
      body: JSON.stringify(teamMember),
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchGetTeamMemberReminders = (teamId, teamMemberId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/members/${teamMemberId}/reminders`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const postNotificationTeamSettings = (teamId, userId, data) => (dispatch, getState) => {
  dispatch(
    fetchApi({
      url: `${config.TeleKardiaBaseUrl}api/v1/teams/${teamId}/admin/${userId}/notification-settings`,
      authType: AUTH_TYPES.BEARER,
      method: 'POST',
      body: JSON.stringify(data),
    }),
  ).catch((err) => {
    throw err;
  });
};

export const fetchNotificationTeamSettings = (teamId, userId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${config.TeleKardiaBaseUrl}api/v1/teams/${teamId}/admin/${userId}/notification-settings`,
      authType: AUTH_TYPES.BEARER,
      method: 'GET',
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const postBusinessHoursTeamSettings =
  (teamId, userId, multiple = false, data, post, id = null, del = false) =>
  (dispatch, getState) => {
    console.log(data, post, id, del);
    const API_URL = `api/v1/teams/${teamId}/admin/${userId}/businessHour`;
    let URL;
    if (!post || del) {
      URL = `${API_URL}/${id}`;
    } else {
      URL = multiple ? `${API_URL}/multiple` : API_URL;
    }

    dispatch(
      fetchApi({
        url: `${config.TeleKardiaBaseUrl}${URL}`,
        authType: AUTH_TYPES.BEARER,
        method: del ? 'DELETE' : post ? 'POST' : 'PUT',
        body: JSON.stringify(data),
      }),
    ).catch((err) => {
      throw err;
    });
  };

export const fetchBusinessHoursTeamSettings = (teamId, userId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${config.TeleKardiaBaseUrl}api/v1/teams/${teamId}/admin/${userId}/businessHour`,
      authType: AUTH_TYPES.BEARER,
      method: 'GET',
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchPostAcceptTeamMember = (newTeamMember) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KCC_GOKARDIA_API}i/v1/unified/addMember`,
      authType: AUTH_TYPES.NONE,
      method: 'POST',
      body: JSON.stringify(newTeamMember),
    }),
  )
    .then((res) => res.json())
    .then((res) => {
      if (res?.errors) {
        // dispatch(dataSourceSuccess(DATASOURCES.REGISTRATION_ERROR, 'You are already registered'));
        // return dispatch(displayValidationError(res?.error));
        return dispatch(displayValidationError(res?.errors[0].title));
      }
    })
    .catch((err) => {
      throw err;
    });

export const fetchPrescriptions = (teamId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${getApiBaseUrl()}/i/v1/teams/${teamId}/templates`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const fetchActivity = (teamId, startDate, endDate) => (dispatch, getState) => {
  let getQueryParams = '';
  if (startDate) {
    const uriEncodedStartTime = generateQueryParam('startTime', startDate);
    getQueryParams = `?${uriEncodedStartTime}`;
  }

  if (endDate) {
    const uriEncodedEndTime = generateQueryParam('endTime', endDate);
    getQueryParams =
      getQueryParams.length === 0
        ? `?${uriEncodedEndTime}`
        : `${getQueryParams}&${uriEncodedEndTime}`;
  }

  return dispatch(
    fetchApi({
      url: `${getApiBaseUrl()}/i/v1/teams/${teamId}/activity${getQueryParams}`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

export const fetchInterpretations = (teamId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_GOKARDIA_API_BASE}i/v1/teams/${teamId}/interpretations`,
      authType: AUTH_TYPES.BEARER,
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

    export const fetchPatient = (patientId, programType) => (dispatch, getState) => {
      // Determining the query string based on the programType
      const query = programType?.userType === "CONSUMER" ? "?limit=500&days=30" : "?limit=500";
      
      // FOR KCC users we are keeping 30 days data for SIMBA users we are keeping all data.
      const url = `${process.env.REACT_APP_KCC_GOKARDIA_API}i/v2/unified/participants/${patientId}${query}`;
    
      return dispatch(
        fetchApi({
          url: url,
          authType: AUTH_TYPES.BEARER,
        }),
      )
        .then((res) => res.json())
        .catch((err) => {
          throw err;
        });
    };

export const fetchShippingDetails = (memberID) => {
  return KccGoAPI.get(`i/v1/kcc/shippingDetails/${memberID}`, {
    headers: {
      Authorization: ` Bearer ${getToken()}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchSamples = (sampleId) => {
  return KproService.get(`i/v1/recordings/${sampleId}/samples`, {
    headers: {
      Authorization: ` Bearer ${getToken()}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchDeleteLicense = (memberId, member_license_id) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KPRO_SERVICE}/i/v1/members/${memberId}/license/${member_license_id}`,
      authType: AUTH_TYPES.BEARER,
      method: 'DELETE',
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const postEditLicense = (member_id, data) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KPRO_SERVICE}/i/v1/members/${member_id}/license`,
      authType: AUTH_TYPES.BEARER,
      method: 'PUT',
      body: JSON.stringify(data),
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const postAddLicense = (memberId, data) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KPRO_SERVICE}/i/v1/members/${memberId}/license`,
      authType: AUTH_TYPES.BEARER,
      method: 'POST',
      body: JSON.stringify(data),
    }),
  ).catch((err) => {
    throw err;
  });

export const fetchLicenseTable = (memberId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KPRO_SERVICE}/i/v1/members/${memberId}/license`,
      authType: AUTH_TYPES.BEARER,
      method: 'GET',
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });

export const fetchCoveredStates = (memberId) => (dispatch, getState) =>
  dispatch(
    fetchApi({
      url: `${process.env.REACT_APP_KPRO_SERVICE}/i/v1/members/${memberId}/states`,
      authType: AUTH_TYPES.BEARER,
      method: 'GET',
    }),
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
