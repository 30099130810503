import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const LineChart = ({ completeRecordings }) => {
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);

  // Helper function to set time to 12:00 AM (start of the day)
  const setToMidnight = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0); // Sets hours, minutes, seconds, and milliseconds to 0
    return newDate;
  };

  // Filter and Sort Recordings within the last 30 days
  const filteredRecordings = completeRecordings.filter((recording) => {
    const recordedDate = new Date(recording.recorded_at);
    return recordedDate >= thirtyDaysAgo && recordedDate <= today;
  });

  const sortedRecordings = filteredRecordings.sort(
    (a, b) => new Date(a.recorded_at) - new Date(b.recorded_at)
  );
  //  Group the data by date (ignoring time) and calculate averages
  const groupedData = d3.group(
    sortedRecordings,
    (d) => d3.timeFormat("%Y-%m-%d")(setToMidnight(new Date(d.recorded_at)))
  );
  const averagedData1 = [];
  const averagedData2 = [];

  groupedData.forEach((values, key) => {
    const avgSystolic = d3.mean(values, (d) => d.systolic);
    const avgDiastolic = d3.mean(values, (d) => d.diastolic);
    const date = setToMidnight(new Date(key)); // Set the date explicitly to 12:00 AM as we have to plot the data excatly on the Date 
    averagedData1.push({ date, value: avgSystolic });
    averagedData2.push({ date, value: avgDiastolic });
  });

  const svgRef = useRef();

  useEffect(() => {
    const margin = { top: 20, right: 100, bottom: 70, left: 70 };
    const width = 2000 - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3.scaleTime().domain([thirtyDaysAgo, today]).range([0, width]);
    const y = d3.scaleLinear().domain([40, 200]).range([height, 0]);

    // Gridlines for Y axis
    const yAxisGridlines = d3.axisLeft(y).tickSize(-width).ticks(20);

    svg
      .append("g")
      .attr("class", "y-axis-grid")
      .call(yAxisGridlines)
      .selectAll("line")
      .attr("stroke", "#B5BCC1")
      .attr("stroke-opacity", 0.2)
      .attr("stroke-height", 1);

    // Background rectangles for ranges
    svg
      .append("rect")
      .attr("x", 0)
      .attr("y", y(140))
      .attr("width", width)
      .attr("height", y(90) - y(140))
      .attr("fill", "#CDE5E2")
      .attr("opacity", 0.5);

    svg
      .append("rect")
      .attr("x", 0)
      .attr("y", y(90))
      .attr("width", width)
      .attr("height", y(60) - y(90))
      .attr("fill", "#F7D1BC")
      .attr("opacity", 0.1);

    // X Axis for Dates
    const xAxis = d3
      .axisBottom(x)
      .ticks(d3.timeDay.every(1))
      .tickSize(0)
      .tickFormat((d) => d3.timeFormat("%d")(d));

    const xAxisMonth = d3
      .axisBottom(x)
      .ticks(d3.timeDay.every(1))
      .tickSize(0)
      .tickFormat((d, i, nodes) => {
        const formatMonth = d3.timeFormat("%b");
        const currentMonth = formatMonth(d);
        const previousMonth = i > 0 ? formatMonth(nodes[i - 1].__data__) : "";
        return currentMonth !== previousMonth ? currentMonth : "";
      });

    // Append X axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "middle")
      .attr("dy", "35px");

    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(xAxisMonth)
      .selectAll("text")
      .style("text-anchor", "middle")
      .attr("fill",  "#757575") 
      .attr("dy", "55px");

    // Append axis labels

    svg
    .append("text")
    .attr("x", -60) // To bring the Month to left 
    .attr("y", height + 58)
    .attr("text-anchor", "start")
    .attr("font-size", 12)
    .attr("fill",  "#757575") 
    // .attr("fill-opacity", "0.4")
    .style("font-weight", 350)
    .text("MONTH");

    svg
      .append("text")
      .attr("x", -60) //To bring the Date to left
      .attr("y", height + 38)
      .attr("text-anchor", "start")
      .attr("font-size", 14)
      .attr("fill", "rgba(0, 0, 0, 0.87) !important")
      .attr("font-weight", 400)
      .text("DATE");

 
    svg
      .append("g")
      .call(d3.axisLeft(y).ticks(20).tickSize(0).tickFormat(d3.format("d")));

    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("font-weight", 400)
      .text("BP");

    // Line for systolic values
    const line1 = d3
      .line()
      .x((d) => x(d.date)) // Use date without time (midnight)
      .y((d) => y(d.value)); // Use averaged systolic value

    svg
      .append("path")
      .datum(averagedData1) // Use averaged systolic data
      .attr("fill", "none")
      .attr("stroke", "#182C3D")
      .attr("stroke-width", 1.5)
      .attr("d", line1);

    // Points for systolic values
    svg
      .selectAll(".dot1")
      .data(averagedData1)
      .enter()
      .append("circle")
      .attr("class", "dot1")
      .attr("cx", (d) => x(d.date))
      .attr("cy", (d) => y(d.value))
      .attr("r", 3)
      .attr("fill", "#182C3D");

    // Line for diastolic values
    const line2 = d3
      .line()
      .x((d) => x(d.date))
      .y((d) => y(d.value));

    svg
      .append("path")
      .datum(averagedData2)
      .attr("fill", "none")
      .attr("stroke", "#182C3D")
      .attr("stroke-width", 1.5)
      .attr("d", line2);

    // Points for diastolic values
    svg
      .selectAll(".dot2")
      .data(averagedData2)
      .enter()
      .append("path")
      .attr("class", "dot2")
      .attr("d", d3.symbol().type(d3.symbolTriangle).size(64))
      .attr("transform", (d) => `translate(${x(d.date)},${y(d.value)})`)
      .attr("fill", "#182C3D");

    // Remove extra domain lines
    svg.selectAll(".domain").remove();
  }, [averagedData1, averagedData2]);

  return (
    <div style={{ height: "515px", width: "100%", overflowX: "auto" }}>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default LineChart;
