import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Strophe } from 'strophe.js';
import { getFullJid } from 'utilities/Utilities';
import { InitChatWithUser } from 'providers/chat';
import { getToken } from 'auth/AuthUtilities';
import { upLoadFiles } from 'store/actions';
import { fileUploadSuccess } from 'store/actions';

const WithChat = (WrapperComponent) => {
  return function Comp(props) {
    const {
      chatFirstMessage,
      messages,
      rawMessages,
      status,
      recStatus,
      typing,
      sendMessage,
      getHistory,
      getPrescence,
      fetchingHistory,
      getHistoryFromTk,
      scrollWayUp,
    } = InitChatWithUser(props.chatUserId);
    const dispatch = useDispatch();
    const { loadMoreCount } = useSelector((state) => state.chat);

    const [message, setMessage] = React.useState('');
    /** content ref for chat body where messages rendered */
    const contentRef = React.useRef(null);
    /** previous scroll height of chat body */
    const prevScrollHeight = React.useRef(0);

    const chatCreds = useSelector((state) => state.user.chatProfile);
    const profileId = useSelector((state) => state.user?.profile.id);

    useEffect(() => {
      if (status === Strophe.Status.CONNECTED && props.chatUserId) {
        prevScrollHeight.current = 0;
        // getHistory({ with: getFullJid(props.chatUserId), before: '', max: 500 }, false);

        getPrescence(getFullJid(props.chatUserId));
      }
    }, [status, props.chatUserId, chatCreds.kccChatUserID, getPrescence, getHistory]);

    useEffect(() => {
      getHistoryFromTk(profileId, props.chatUserId, loadMoreCount, '', getToken(), [], {});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chatUserId, status]);

    const loadMoreChat = useCallback(
      (messageId, oldRawMessages, oldMessagesGroupedByDay) => {
        getHistoryFromTk(
          profileId,
          props.chatUserId,
          loadMoreCount,
          messageId,
          getToken(),
          oldRawMessages,
          oldMessagesGroupedByDay,
        );
      },
      [props.chatUserId],
    );

    const sendMessageHandler = (msg) => {
      if (msg) {
        prevScrollHeight.current = 0;
        sendMessage(msg, getFullJid(chatCreds.kccChatUserID), getFullJid(props.chatUserId)).then(
          () => {
            // Once file meseage is uploaded prev mesg to be removed from redux
            setMessage('');
            dispatch(fileUploadSuccess());
          },
        );
      }
    };

    /**
     * get last 10 messages of chat after given message id
     */
    const handleRefresh = () => {
      getHistory(
        { with: getFullJid(props.chatUserId), max: 10, after: chatFirstMessage.id },
        false,
      );
    };
    /** function used to set element scroll position to bottom */
    const gotoBottom = (element) => {
      element.scrollTop = element.scrollHeight - prevScrollHeight.current;
    };

    React.useEffect(() => {
      /**
       * when new message comes or other side person typing then
       * chat body scroll position to bottom
       * */
      if (contentRef.current) {
        gotoBottom(contentRef.current);
      }
    }, [messages, typing]);

    React.useEffect(() => {
      if (contentRef.current) {
        contentRef.current.addEventListener('scroll', (e) => {
          if (
            e.target.scrollTop === 0 &&
            chatFirstMessage?.id &&
            !fetchingHistory &&
            Object.keys(messages).length
          ) {
            prevScrollHeight.current = e.target.scrollHeight;
            handleRefresh();
          }
        });
      }
      return () => {
        if (contentRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          contentRef.current.removeEventListener('scroll', () => {});
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages, chatFirstMessage, fetchingHistory]);

    return (
      <>
        <WrapperComponent
          key={props.chatUserId}
          {...props}
          ref={contentRef}
          // loading={loading}
          fetchingHistory={fetchingHistory}
          messages={messages}
          rawMessages={rawMessages}
          message={message}
          setMessage={setMessage}
          status={status}
          recStatus={recStatus}
          typing={typing}
          sendMessageHandler={sendMessageHandler}
          userChatCreds={chatCreds}
          profileId={profileId}
          loadMoreChat={loadMoreChat}
          scrollWayUp={scrollWayUp}
          upLoadFiles={upLoadFiles}
        />
      </>
    );
  };
};

export default WithChat;
