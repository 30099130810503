import * as actionTypes from '../actions/actionTypes';

const initialState = {
  headerText: '',
  isSideBar: true,
  regionListInformation:[]
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS_HEADER_CHANGE:
      return {
        // ...state,
        headerText: action.headerText,
        isSideBar: action.isSidebar,
      };
      case actionTypes.REGIONS_LIST:
        return {
          ...state,
          regionListInformation:action.regionListInformation
        }
    default:
      return state;
  }
};

export default settingsReducer;
