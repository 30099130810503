import { Grid, Typography, styled } from '@mui/material';
import { useHistory } from 'react-router';
import Chips from 'components/mui/tableElements/Chips';
import { SESSION_PROGRAM, SESSION_PROGRAM_ECG_INBOX, capitalizeFirstLetter } from 'constants/session';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MuiTableComponent } from 'screens/common/CustomUI';

import { countryConfig, formatDate, formatDateTime, formatDate_Update, formatDate_UpdateVersion, statusColorOutliner, timeFormat, toTitleCase } from 'utilities/Utilities';
import { COMPLETED_ECG_INBOX, INCOMING_ECG_INBOX, INCOMMING, US_STATES } from 'constants/app';
import {
  checkRequestStatus,
  getECGInboxTableData,
  requestReviewScreenNavinationRoute,
} from 'Services/ecgInboxService';
import { getOverdueAndNotStartedCount } from 'store/actions';
import {
  API_PAYLOAD_DATE_FORMAT,
  ECG_REQUEST_STATUS,
  END_OF_DATE_FORMAT,
  MUI_FILTER_OPERATOR,
  START_OF_DATE_FORMAT,
  TRANSFORMED_VALUES,
  Transformed_values,
} from '../constants/EcgInboxConstants';
import CustomColumnText from 'components/mui/tableElements/CustomColumnText';
import { getEKGAlgorithmDeterminationDisplayText } from 'constants/recordingMaps';
import EkgSearch from '../EkgSearch/EkgSearch';
import moment from 'moment';
import { ecgTablePageNumber } from 'store/actions/EkgListTableActions';
import { getregiondetails } from 'store/actions/settings';
import { CANADADATE_TIME_FORMAT, CANADA_DATE_FORMAT, DATE_FORMAT, DATE_TIME_FORMAT } from 'utilities/Constants';

export const TableRowText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Work Sans',
}));

/**
 * mui x-data-grid table for ECG Inbox screen.
 * @param {string} tab selection (either 0 or 1)
 * '0' is for incoming table
 * '1' is for completed table
 * @returns mui x-data-grid table for both incoming or completed tabs based on tab selection.
 */
const EcgInboxTable = ({ status }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  //====== component level state =======//
  const [loading, setloading] = useState(false);
  const [filteredRow, setFilteredRow] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [apiQueryParams, setapiQueryParams] = useState({
    pageSize: pageSize,
    page: 1,
    search: '',
    sortOrder: status === INCOMMING ? 'asc' : 'desc',
    sortBy: status === INCOMMING ? 'timeRequested' : 'timeSubmitted',
    status: status,
    filters: null,
  });

  // ========= redux status ============= //
  const { isAdmin } = useSelector((state) => state.user.profile.permissions);
  const refreshTime = useSelector((state) => state.ekgList?.refreshTime);
  const userCountry = useSelector((state) => state.user.profile.country);
  const countryDetails = useSelector((state) => state.settings.regionListInformation);
  const teamId = useSelector((state) => state.user.team.id);

  useEffect(() => {
    setapiQueryParams((prev) => ({
      ...prev,
      page: page + 1,
      pageSize: pageSize,
    }));
    //*******Dispatch will help to update the page size */
    dispatch(ecgTablePageNumber(page + 1));
  }, [page, pageSize]);

  useEffect(() => {
    tableAPICall(apiQueryParams);
  }, [apiQueryParams, refreshTime]);

  useEffect(() => {
    dispatch(getregiondetails(teamId));
  }, []);

  const tableAPICall = (queryParams = null) => {
    setloading(true);
    dispatch(getOverdueAndNotStartedCount());

    // for incoming request timeSubmit query param is not needed
    status === INCOMMING && delete queryParams.timeSubmittedOrder;

    getECGInboxTableData(queryParams).then((res) => {
      setFilteredRow(transformAppointmentList(res?.result || []));
      setTotalRowCount(res?.totalCount);
      setloading(false);
    });
  };

  /**
   *
   * @param {Array} list
   * @returns array of object which includes custom columns along with api responses
   *  --> startDate, name, age, gender, statusMethods
   */
  const transformAppointmentList = (list) => {
    return list?.reduce((acc, cur) => {
      return [
        ...acc,
        {
          ...cur,
          name: `${cur?.lastName}, ${cur?.firstName}`,
          determination: getEKGAlgorithmDeterminationDisplayText(
            cur?.interpretation,
            cur?.algorithmPackage,
            cur?.heartRate,
          ),
          provider: `${cur?.providerLastName}, ${cur?.providerFirstName}`,
        },
      ];
    }, []);
  };

  // table column for incoming request
  const incommingColumn = [
    {
      field: 'name',
      headerName: 'Member',
      width: 200,
      editable: false,
      filterable: false,
      type: 'string',
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.name || '--'}</TableRowText>
      ),
    },
    {
      field: 'reportId',
      headerName: 'Report Id',
      width: 100,
      editable: false,
      filterable: false,
      type: 'number',
      valueGetter: (params) => params?.row?.reportId,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => <TableRowText variant="body2">{params?.row?.reportId}</TableRowText>,
    },
    {
      field: 'interpretation',
      headerName: 'AI Determination',
      width: 200,
      editable: false,
      type: 'string',
      valueGetter: (params) => params?.row?.determination,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params.row.determination}</TableRowText>
      ),
    },
    {
      field: 'timeRequested',
      headerName: 'Time Requested',
      width: 200,
      type: 'dateTime',
      valueGetter: (params) =>
        `${formatDateTime(params?.row?.timeRequested, userCountry)} ${timeFormat(
          params?.row?.timeRequested,
        )}`,
        valueFormatter: ({ value }) => value, 
      renderCell: (params) => (
        <TableRowText variant="body2">{`${formatDate(
          params?.row?.timeRequested,
          userCountry,
        )} ${timeFormat(params?.row?.timeRequested)}`}</TableRowText>
      ),
    },
    {
      field: 'timeleft',
      headerName: 'Time Left',
      width: 120,
      filterable: false,
      type: 'string',
      valueFormatter: ({ value }) => value,
      renderCell: (params) => (
        <CustomColumnText
          label={params?.row?.timeleft}
          color={statusColorOutliner(params?.row?.status)}
        />
      ),
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      type: 'singleSelect',
      valueOptions: ECG_REQUEST_STATUS.incoming,
      valueGetter: (params) => params?.row?.status,
      renderCell: (params) => (
        <Chips
          label={params?.row?.status || '--'}
          color={statusColorOutliner(params?.row?.status)}
        />
      ),
    },

    ...(isAdmin
      ? [
          {
            field: 'reviewType',
            headerName: 'Review Type',
            width: 150,
            editable: false,
            type: 'string',
            valueFormatter: ({ value }) => `${value}`,
            renderCell: (params) => (
              <TableRowText variant="body2">{params?.row?.reviewType || '--'}</TableRowText>
            ),
          },
          {
            field: 'program',
            headerName: 'Program',
            width: 180,
            type: 'singleSelect',
            valueFormatter: ({ value }) => `${value}`,
            valueOptions: SESSION_PROGRAM_ECG_INBOX,
            renderCell: (params) => (
              <TableRowText variant="body2">{params?.row?.program || '--'}</TableRowText>
            ),
          },
        ]
      : []),

    {
      field: 'location',
      headerName: 'Location',
      width: 100,
      type: 'singleSelect',
      valueOptions: countryDetails && countryDetails[0]?.regions.map((item) => item),
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.location || '--'}</TableRowText>
      ),
    },
  ];

  // table column for completed request
  const completedColumn = [
    {
      field: 'name',
      headerName: 'Member',
      width: 200,
      editable: false,
      filterable: false,
      type: 'string',
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.name || '--'}</TableRowText>
      ),
    },
    {
      field: 'reportId',
      headerName: 'Report Id',
      width: 80,
      editable: false,
      filterable: false,
      type: 'number',
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.reportId || '--'}</TableRowText>
      ),
    },
    {
      field: 'interpretation',
      headerName: 'AI Determination',
      width: 200,
      editable: false,
      type: 'string',
      valueGetter: (params) => params?.row?.determination,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params.row?.determination || '--'}</TableRowText>
      ),
    },


    ...(isAdmin
      ? [
          {
            field: 'quality',
            headerName: 'Quality',
            width: 150,
            editable: false,
            type: 'string',
            valueFormatter: ({ value }) => `${value}`,
            renderCell: (params) => (
              <TableRowText variant="body2">{capitalizeFirstLetter(params?.row?.quality) || '--'}</TableRowText>
            ),
          },
      ] : []),
    
    {
      field: 'baseRhythm',
      headerName: 'Interpretation',
      width: 200,
      editable: false,
      type: 'string',
      valueGetter: (params) => params?.row?.baseRhythm,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.baseRhythm || '--'}</TableRowText>
      ),
    },
    {
      field: 'acuity',
      headerName: 'Acuity',
      width: 150,
      editable: false,
      type: 'string',
      valueGetter: (params) => params?.row?.acuity,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{toTitleCase(params?.row?.acuity) || '--'}</TableRowText>
      ),
    },
    {
      field: 'timeRequested',
      headerName: 'Time Requested',
      width: 200,
      type: 'date',
      valueGetter: (params) =>
        `${formatDateTime(params?.row?.timeRequested, userCountry)} ${timeFormat(
          params?.row?.timeRequested,
        )}`,
      valueFormatter: ({ value }) => value,
      renderCell: (params) => (
        <TableRowText variant="body2">
          {`${formatDate(params?.row?.timeRequested, userCountry)} ${timeFormat(
            params?.row?.timeRequested,
          )}` || '--'}
        </TableRowText>
      ),
    },
    {
      field: 'timeSubmitted',
      headerName: 'Time Submitted',
      width: 200,
      type: 'date',
      valueGetter: (params) =>
        `${formatDateTime(params?.row?.timeSubmitted, userCountry)} ${timeFormat(
          params?.row?.timeSubmitted,
        )}`,
        valueFormatter: ({ value }) => value,
      renderCell: (params) => (
        <TableRowText variant="body2">
          {`${formatDate(params?.row?.timeSubmitted, userCountry)} ${timeFormat(
            params?.row?.timeSubmitted,
          )}` || '--'}
        </TableRowText>
      ),
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      type: 'singleSelect',
      valueOptions: ECG_REQUEST_STATUS.completed,
      valueGetter: (params) => params?.row?.status,
      renderCell: (params) => (
        <Chips
          label={params?.row?.status || '--'}
          color={statusColorOutliner(params?.row?.status)}
        />
      ),
    },
    ...(isAdmin
      ? [
          {
            field: 'provider',
            headerName: 'Provider',
            width: 150,
            editable: false,
            type: 'string',
            valueFormatter: ({ value }) => `${value}`,
            renderCell: (params) => (
              <TableRowText variant="body2">{params?.row?.provider || '--'}</TableRowText>
            ),
          },
          {
            field: 'reviewType',
            headerName: 'Review Type',
            width: 150,
            editable: false,
            type: 'string',
            valueFormatter: ({ value }) => `${value}`,
            renderCell: (params) => (
              <TableRowText variant="body2">{params?.row?.reviewType || '--'}</TableRowText>
            ),
          },
          {
            field: 'program',
            headerName: 'Program',
            width: 180,
            type: 'singleSelect',
            valueFormatter: ({ value }) => `${value}`,
            valueOptions: SESSION_PROGRAM_ECG_INBOX,
            renderCell: (params) => (
              <TableRowText variant="body2">{params?.row?.program || '--'}</TableRowText>
            ),
          },
        ]
      : []),

    {
      field: 'location',
      headerName: 'Location',
      width: 100,
      type: 'singleSelect',
      valueOptions: countryDetails && countryDetails[0]?.regions.map((item) => item),
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.location || '--'}</TableRowText>
      ),
    },
  ];

  /**
   * custom functionality for mui data grid table's
   * custom filter
   * custom sorting
   * and custom column visibility
   */
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

  const onSortModelChange = useCallback((param) => {
    // this if condition is for incomming request table sorting
    if (param[0]?.field === 'timeleft' || param[0]?.field === 'timeRequested') {
      setapiQueryParams((prev) => ({
        ...prev,
        timeRequestedSortOrder: param[0]?.sort,
      }));
    }

    //this is for comleted request table soting
    if (param[0]?.field === 'timeSubmitted') {
      setapiQueryParams((prev) => ({
        ...prev,
        timeSubmittedSortOrder: param[0]?.sort,
      }));
    }
  }, []);

  const onFilterModelChange = useCallback((param) => {
    const filterPayload = param.items
      .filter((item) => item.columnField !== 'provider')
      .reduce((acc, curr) => {
        let existing = acc.find((item) => item.column_name === curr.columnField);
        if (
          existing &&
          curr?.columnField !== 'timeRequested' &&
          curr?.columnField !== 'timeSubmitted'
        ) {
          existing.values.push(curr.value);
        } else {
          acc.push({
            column_name: curr.columnField,
            operation:
              (curr.columnField === 'timeRequested' || curr.columnField === 'timeSubmitted') &&
              curr.operatorValue === 'is'
                ? MUI_FILTER_OPERATOR.isInRange
                : MUI_FILTER_OPERATOR[curr.operatorValue] || curr.operatorValue,
            values:
              curr.columnField === 'timeRequested' || curr.columnField === 'timeSubmitted'
                ? curr.operatorValue === 'is'
                  ? [
                      moment(curr.value).format(START_OF_DATE_FORMAT),
                      moment(curr.value).format(END_OF_DATE_FORMAT),
                    ]
                  : [moment(curr.value).format(API_PAYLOAD_DATE_FORMAT)]
                : curr.columnField === 'interpretation'
                ? TRANSFORMED_VALUES[curr.value]?.flat() || [curr.value]?.flat() 
                : curr.columnField === 'program'
                ? [curr.value?.replace('+', '%2B')]
                : [curr.value?.length > 0 ? curr.value : null].flat(),
          });
        }
        return acc;
      }, []);

    const children = param.items
      .filter((item) => item.columnField === 'provider')
      .reduce((acc, curr) => {
        let existing = acc.find((item) => item.column_name === 'providerFirstName');
        if (existing) existing.values.push(curr.value);
        else
          acc.push(
            {
              column_name: 'providerFirstName',
              operation: MUI_FILTER_OPERATOR[curr.operatorValue] || curr.operatorValue,
              values: [curr.value],
            },
            {
              column_name: 'providerLastName',
              operation: MUI_FILTER_OPERATOR[curr.operatorValue] || curr.operatorValue,
              values: [curr.value],
            },
          );
        return acc;
      }, []);

    setapiQueryParams((prev) => ({
      ...prev,
      filters: filterPayload,
      children: children,
    }));
  }, []);

  // ============= end custom filter/sortng/visibilty =========//

  /**
   *
   * @param {string} reportId report id of clicked request
   * returns null
   * navigate to the request review screen for that specific request (incoming/pending/completed).
   */
  const onRowClick = (param) => {
    // check the status of request and logic to navigate or showing error popup
    status === INCOMMING
      ? checkRequestStatus(param.row?.id)
          .then((res) =>
            !res ? requestReviewScreenNavinationRoute(history, status, param.id, isAdmin) : null,
          )
          .catch((err) => console.log(err))
      : requestReviewScreenNavinationRoute(history, param.row.status, param.row.id, isAdmin);
  };

  const onSearchClickHandler = useCallback((searchValue) => {
    // if (searchValue.trim().length === 0) {
    //   return;
    // }
    setapiQueryParams((prev) => ({
      ...prev,
      search: searchValue,
    }));
  }, []);

  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid item xs={12}>
        <EkgSearch clickHandler={onSearchClickHandler} />
      </Grid>
      <Grid item xs={12}>
        <MuiTableComponent
          fileName={status === INCOMMING ? INCOMING_ECG_INBOX : COMPLETED_ECG_INBOX}
          getRowId={(row) => row.id}
          loading={loading}
          columns={status === INCOMMING ? incommingColumn : completedColumn}
          rows={filteredRow}
          columnToolbar={true}
          filterToolbar={true}
          densityToolbar={true}
          exportToolbar={isAdmin}
          exportToolbarOptions={{
            excelOptions: {
              columnsStyles: {
                timeRequested: { numFmt: countryConfig(userCountry) },
                timeSubmitted: { numFmt: countryConfig(userCountry) },
              },
            },
            csvOptions: {
              columnsStyles: {
                timeRequested: { numFmt: countryConfig(userCountry) },
                timeSubmitted: { numFmt: countryConfig(userCountry) },
              },
            },
          }}
          pagination
          paginationMode="server"
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          rowsPerPageOptions={[20, 50, 100, totalRowCount]}
          rowCount={totalRowCount}
          filterMode="server"
          onFilterModelChange={(param) => onFilterModelChange(param)}
          // sortingMode="server"
          onSortModelChange={(param) => onSortModelChange(param)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          onRowClick={(param) => onRowClick(param)}
          
          // dataforgrouping={'reportId'}
        />
      </Grid>
    </Grid>
  );
};

export default EcgInboxTable;
