import React from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { CustomComponent } from './Screens';

const EcgHeartratedata = ({ heartrate }) => {
  return (
    <>
      {heartrate ? (
        <Box>
          <Button style={{ color: 'red' }} startIcon={<FavoriteIcon />}>
            <Box style={{ display: 'flex', gap: '4px' }}>
              <CustomComponent text={heartrate} variant={'body1editied'} />
              <CustomComponent text={'bpm'} variant={'heartbeatvalue'} />
            </Box>
          </Button>
        </Box>
      ) : null}
    </>
  );
};

export default EcgHeartratedata;
