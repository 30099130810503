import React, { useContext, useState, useEffect } from 'react';
import { Strophe } from 'strophe.js';
import { useHistory } from 'react-router-dom';
import styles from './NavigationSidebar.module.css';
import { useSelector } from 'react-redux';
import { ChatContext } from 'providers/chat';
import { useTranslation } from 'react-i18next';
import TeamProfile from './components/TeamProfile';
import NavigationBuilder from './components/NavigationBuilder';
import { fetchConfigs } from './config';
import { AUTH_TYPE, DOCTOR_ROUTES } from 'constants/app';

const Navigation = (props) => {
  const { status, unreadMsgCount } = useContext(ChatContext);
  const { me, updatePrevLocation, handleLogout, userType, sidebarIsCollapsed } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const [unreadCount, setunreadCount] = useState(0);
  const alertCount = useSelector((state) => state.escalation?.alertCount);
  const ekgCount = useSelector((state) => state.ekgListTable?.notstartedAndOverdueCount);
  const audioCount = useSelector((state) => state.audio?.count);

  useEffect(() => {
    status === Strophe.Status.CONNECTED &&
      setunreadCount(
        Object.values(unreadMsgCount).reduce((acc, cur) => {
          return acc + cur;
        }, 0),
      );
  }, [unreadMsgCount]);

  const isAdmin = me.data ? me.data.profile?.permissions?.isAdmin : false;
  const isTeleConnect = me.data ? me.data.profile?.access?.isTeleConnect : false;
  const isECGReview = me.data ? me.data.profile?.access?.isECGReview : false;
  const isAudioReview = me.data ? me.data.profile?.access?.isAudioReview : false;

  const [config, setConfig] = useState({ main: [], footer: [] });
  useEffect(() => {
    let data = null;
    if (userType) {
      //fetches role configs and populates sidebar based on the user type
      data = fetchConfigs(
        isAdmin,
        isTeleConnect,
        isECGReview,
        isAudioReview,
        history,
        unreadCount,
        status,
        alertCount,
        ekgCount,
        audioCount,
      );
      if (data) {
        switch (userType) {
          case AUTH_TYPE.DOCTOR:
            setConfig(data.doctorConfig);
            break;
          case AUTH_TYPE.COACH:
            setConfig(data.coachConfig);
            break;
          case AUTH_TYPE.OPERATIONS:
            setConfig(data.opsConfig);
            break;
          default:
            break;
        }
      }
    }
    return () => setConfig({ main: [], footer: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, status, unreadCount, alertCount, ekgCount, audioCount]);

  return (
    <React.Fragment>
      <NavigationBuilder
        main={config.main}
        footer={config.footer}
        updatePrevLocation={updatePrevLocation}
        handleLogout={handleLogout}
        isLoading={config.main.length == 0 && config.footer.length == 0}
        sidebarIsCollapsed={sidebarIsCollapsed}
      />
    </React.Fragment>
  );
};

export const NavigationSidebar = (props) => {
  const data = useSelector((state) => state.user);
  const {
    chatProfile: { userType },
  } = data;

  const { sidebarIsCollapsed, handleToggleNavigationSidebar } = props;

  const sidebarClassName = sidebarIsCollapsed ? 'sidebarCollapsed' : 'sidebar';
  return (
    <React.Fragment>
      <aside data-page="navigation-sidebar" className={styles[sidebarClassName]}>
        <TeamProfile
          me={{ isFetching: false, data: data }}
          sidebarIsCollapsed={sidebarIsCollapsed}
          handleToggleNavigationSidebar={handleToggleNavigationSidebar}
        />
        <Navigation userType={userType} me={{ isFetching: false, data: data }} {...props} />
      </aside>
    </React.Fragment>
  );
};

export default NavigationSidebar;
