import React from 'react'
import {Grid, Box} from '@mui/material';
import { CustomComponent } from 'screens/Ecgscreens/Screens'

const BlooPressureRecordingDetails = ({lable, value}) => {
  return (
    <Grid >
    <Box display={"flex"} gap={"8px"}>
        <CustomComponent text={lable} variant={'body1lableedit'}/>
        <CustomComponent text={[null, undefined, ''].includes(value) ? '--' : value  } variant={'subtitle2value'}/>
    </Box>
</Grid>
  )
}

export default BlooPressureRecordingDetails
