export const Table_HEADERNAME={
    DATE:'Date',
    SOURCE:'Source',
    // CATEGORY:'Category',
    BPM:'BPM',
    SYSTOLIC:'Systolic',
    DIASTOLIC:'Diastolic',
    AVGBP:"Avg. BP",
    RECORDINGS:"Recordings",
    TIME:"Time",
    MEMBER:'Member',
    PROVIDER:'Provider',
    AGE:'Age',
    REGION:'Region',
    PROGRAM:'Program',
    CATEGORY:'Category',
    TYPE:'Type',
    STATUS:'Status',
    MEATHOD_REASON:'Method/Reason',
    ACTIONS:'Actions',
    TOGGLE:'Toggle'
}