import { Box, styled } from '@mui/material';
import React from 'react';

const ChipContainer = styled(Box, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== 'color',
  name: 'ChipContainer',
  slot: 'Root',
})(({ theme, color }) => ({
  border: `2px solid ${color}`,
  borderRadius: theme.spacing(4),
  padding: '2px 8px',
  fontSize: 14,
  fontFamily: 'Work Sans',
  fontWeight: 500,
  textTransform: 'uppercase',
}));

const Chips = ({ label, color }) => {
  return <ChipContainer color={color}>{label}</ChipContainer>;
};

export default Chips;
