import { dispatch } from 'd3';
import * as actionTypes from './actionTypes';
import { GoAPI } from 'Axios/axios';
import { getToken } from 'auth/AuthUtilities';

export const toggleHeader = (text, isSideBar) => ({
  type: actionTypes.SETTINGS_HEADER_CHANGE,
  headerText: text,
  isSidebar: isSideBar,
});

export const handletoggleHeader = (text, isSideBar) => (dispatch, getState) => {
  dispatch(toggleHeader(text, isSideBar));
};


// This API will give the country Details to add a new members 
export const getregiondetails =(teamId)=> (dispatch)=> {
  GoAPI.get(`i/v1/teams/${teamId}/crRegions`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  })
    .then((res) => {
      let data=res.data
      dispatch(regionListInformation(data))
    })
    .catch((err) => console.log(err));
}
export const regionListInformation = (regions) => {
  return {
    type: actionTypes.REGIONS_LIST,
    regionListInformation: regions
  };
};