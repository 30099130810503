/* eslint-disable import/no-unresolved */
import { Box, Paper } from '@mui/material';
import React from 'react';
import SectionBody from './SectionBody';
import SectionFooter from './SectionFooter';
import SectionHeader from './SectionHeader';
import classes from './Section.module.css';

export const SectionMain = (props) => {
  const { headerElement = {}, bodyElement = [], footerElement = {}, sx = {} } = props;
  return (
    <Paper className={classes.mainSection} sx={sx}>
      {headerElement ? (
        <SectionHeader
          icon={headerElement?.icon}
          headerText={headerElement?.headerText}
          helperText={headerElement?.helperText}
          subheaderText={headerElement?.subheaderText}
          action={headerElement?.action}
          childElements={headerElement?.childElements}
        />
      ) : null}

      {bodyElement
        ? bodyElement.map((item, index) => (
            <Box key={index}>
              <SectionBody
                sx={item?.sx}
                headerText={item?.headerText}
                subheaderText={item?.subheaderText}
                headerElement={item.headerElement}
                childElements={item?.childElements}
              />
            </Box>
          ))
        : null}
      {footerElement ? (
        <SectionFooter {...footerElement} buttonInputProps={footerElement?.buttonInputProps} />
      ) : null}
    </Paper>
  );
};
