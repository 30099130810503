import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { Button, Paper, styled } from '@mui/material';
import moment from 'moment';
import { getAge, getTimezoneName } from 'utilities/Utilities';
import ToastButton from '../ContentHeader/ToastButton';
import * as actions from 'store/actions';
// import { Chat } from '../../providers';
import MemberMissedAlert from 'screens/coach/Common/MemberMissedAlert';
import { ChatContext } from 'providers/chat';
import { TeleKardiaAPI } from '../../Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import { getMemberProfileDetails } from 'store/actions/memberProfile';
import { AUTH_TYPE, BOSH_CALLS, COACH_ROUTES, DOCTOR_ROUTES } from 'constants/app';
import {
  AppointmentStatusTypes,
  SessionChannelTypeId,
  consultationType,
} from 'utilities/Constants';

const RootMuiAlert = styled(MuiAlert)((theme) => ({
  root: {
    '& .MuiAlert-message': {
      padding: '0 !important',
    },
  },
}));
function Alert(props) {
  return (
    <RootMuiAlert
      style={{
        backgroundColor: props.bg,
        fontFamily: 'Work Sans',
        fontWeight: 'bold',
        fontSize: '14px',
        width: 'auto',
        visibility: props.visibility,
        display: 'flex',
        alignItems: 'center',
      }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

const AppNotification = () => {
  const { currentAppointmentUser: caUser } = useContext(ChatContext);
  const [minLeft, setminLeft] = useState('');
  const [isSessionAlert, setisSessionAlert] = useState(false);
  const [memberWaiting, setmemberWaiting] = useState(false);
  const [appointmentId_, setappointmentId_] = React.useState('');
  const [patientDetails, setPatientDetails] = React.useState({});
  const [recording, setRecording] = React.useState([]);
  const [showAlert, setShowAlert] = useState(false); //state to flag if there is any upcoming session or member has joined the session
  const [showMissedAlert, setshowMissedAlert] = useState(false); //state to flag if member missed session.

  const teamId = useSelector((state) => state.user.team.id);
  const appointments = useSelector((state) => state.events.events);
  const errorMsg = useSelector((state) => state.user.errorMsg);
  //Things needed for opening appointment on click
  // console.log('ERROR MESSAGE', errorMsg);
  const dispatch = useDispatch();
  const history = useHistory();

  const patientDetailsntwrk = useSelector((state) => state.appointments.patientDetails);
  const recordingntwrk = useSelector((state) => state.ekg.recording);
  // const error = useSelector((state) => state.appointments.error);
  const patientDetailsLoader = useSelector((state) => state.appointments.detailsloader);
  const profileId = useSelector((state) => state.user?.profile.id);
  const { isComponentMounted } = useSelector((state) => state.user); // this state represents if any video session is ongoing.
  const { isFloatingWindow } = useSelector((state) => state.adhocCall); // this state represents if any audio/phone session is ongoing.
  const { userType } = useSelector((state) => state.user.chatProfile);

  const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
  //===Setting visibility of toast baner false when coach joined the session===//
  const [visibility, setvisibility] = useState('visible');
  const hideToast = () => {
    setvisibility('hidden');
  };

  /**
   * Adding this as a fallback if events are not fetched to show
   */
  useEffect(() => {
    getUpdatedAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUpdatedAvailability = () => {
    let queryParams = {
      startDate: moment(moment().startOf('day')._d).format(pattern),
      endDate: moment(moment().endOf('day')._d).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      splitBasedOnDays: true,
    };
    dispatch(actions.getAvailabilities(profileId, teamId, queryParams));
  };

  //method that shows banner
  const prepForBanner = (app, waiting) => {
    !isComponentMounted && !isFloatingWindow && setappointmentId_(app.id);
    if (waiting && !isComponentMounted) {
      setmemberWaiting(true);
      setisSessionAlert(false);
      setShowAlert(true);
      setvisibility('visible');
      dispatch(
        actions.getRecordingSamples(app.ekgId, teamId, profileId, app.id, {
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        }),
      );
    } else {
      setmemberWaiting(false);
      setisSessionAlert(true);
      setShowAlert(true);
    }
    return;
  };
  //api call
  useEffect(() => {
    let app = null;
    app = appointments.filter((t) => t.status === 12 && t.eventType === 'appointment');

    if (app.length > 0 && !isComponentMounted) {
      prepForBanner(app[0], true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);

  //bosh call
  useEffect(() => {
    //need to check if there are no current running appointment before setting the appointment id
    // console.log('bosh call coming in!');
    if (caUser && !isComponentMounted) {
      console.log('doing bosh call routine- ', caUser);
      if (caUser.currentMode === BOSH_CALLS.IN_WAITROOM) {
        setvisibility('visible');
        setmemberWaiting(true);
        setisSessionAlert(false);
        setShowAlert(true);
      } else if (caUser.currentMode === BOSH_CALLS.UPCOMING_SESSION) {
        setmemberWaiting(false);
        setisSessionAlert(true);
        setShowAlert(true);
      } else if (caUser.currentMode === BOSH_CALLS.NOT_IN_WAITROOM) {
        //====== Showing alert modal when member did not join the waiting room within 6 mins of sesison =====//
        setmemberWaiting(false);
        setisSessionAlert(false);
        setshowMissedAlert(true);
        setShowAlert(false); // not to show the banner alert when member missed the session
      } else if (
        caUser.currentMode === BOSH_CALLS.COACH_MISSED ||
        caUser.currentMode === BOSH_CALLS.DOCTOR_MISSED
      ) {
        setShowAlert(false); // not to show the banner alert when member missed the session
        // setvisibility('hidden');
      }
      setappointmentId_(caUser.extraData?.appointmentId);
    }
  }, [caUser]);
  //to populate appointment.patientDetails
  useEffect(() => {
    if (appointmentId_ && !isComponentMounted && !isFloatingWindow) {
      console.log('DIAGNOSTIC: regular poller call appappointmentId_ - ', appointmentId_);
      let app = appointments.filter((t) => t.id === appointmentId_);
      dispatch(
        actions.getRecordingSamples(app.ekgId, teamId, profileId, appointmentId_, {
          timeZoneOffset: new Date().getTimezoneOffset(),
          timeZone: getTimezoneName(),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId_, appointments]);

  useEffect(() => {
    if (patientDetailsntwrk.patient && !isComponentMounted) {
      patientDetailsntwrk.patient['age'] = getAge(patientDetailsntwrk.patient.dob);
      setPatientDetails(patientDetailsntwrk);
      setRecording(recordingntwrk);
    }
  }, [patientDetailsntwrk, recordingntwrk]);

  const latestAppointmentWatcher = useRef(null);
  //session is in 'x' mins
  useEffect(() => {
    if (
      appointments.length > 0 &&
      isSessionAlert &&
      appointmentId_ &&
      !isComponentMounted &&
      !isFloatingWindow
    ) {
      const filteredApp = appointments.filter((item) => item.id === appointmentId_);
      if (filteredApp.length > 0 && filteredApp[0].status === AppointmentStatusTypes.Scheduled)
        setvisibility('visible');

      setisSessionAlert(true);
      setShowAlert(true);

      //The wrapper watcher
      if (filteredApp.length > 0) {
        latestAppointmentWatcher.current = setInterval(() => {
          let item = filteredApp[0];
          let start = moment(item.start);
          let diffMins = moment(start).diff(new Date(), 'minute');
          if (diffMins < 15 && diffMins >= 0) {
            setminLeft(diffMins);
          }
        }, 10000);
      }
    } else if (isComponentMounted || isFloatingWindow) setvisibility('hidden');

    return () => {
      // console.log('decomissionsing')
      clearInterval(latestAppointmentWatcher.current);
    };
  }, [appointments, isSessionAlert, appointmentId_, isComponentMounted, isFloatingWindow]);

  //Fail safe poller for waiting room event
  // 1. check if appointments are there
  // 2. if yes, check if any of the appointments starting x mins from now (latest scheduled app)
  // 3. if yes, start poller and poll with appointmemt id for the status
  // 4. poll until the app status is 12
  // 5. cancel poll

  const MainPoller = useRef(null);
  const MainPollerInterval = 60000;
  const PollForAppStatus = useRef(null);
  const PollerInterval = 10000;

  const startAppointmentPoller = (appointmentToPoll) => {
    PollForAppStatus.current = setInterval(() => {
      console.log('DIAGNOSTIC: POLLER STARTED!');
      let version = appointmentToPoll.version;
      TeleKardiaAPI.get(
        `/api/v1/teams/${teamId}/clinicians/${profileId}/appointments/${appointmentToPoll.id}/status?version=${version}`,
        {
          headers: { Authorization: ` Bearer ${getToken()}` },
        },
      )
        .then((res) => {
          //cancelling poller if api fails
          if (res.status !== 200) {
            clearInterval(PollForAppStatus.current);
          }
          const apStatus = res.data.result.appointment_statusid;
          // console.log(`Polled appointment status for ${appointmentToPoll.id} is ${apStatus}`);
          if (
            (apStatus === AppointmentStatusTypes.MemberInWaitingRoom && !isComponentMounted) ||
            (consultationType[appointmentToPoll.appointmentChannelType] ==
              SessionChannelTypeId.AS_SCHEDULED_PHONE &&
              appointmentToPoll.status === AppointmentStatusTypes.Scheduled)
          ) {
            console.log('DIAGNOSTIC: Prep appointment and cancel poller');
            prepForBanner(appointmentToPoll, true);
            clearInterval(PollForAppStatus.current);
          }
        })
        .catch((err) => console.log('error - ', err));
    }, PollerInterval);
  };

  const [callData, setAudioCallData] = useState({
    memberId: '',
    appointmentId: '',
    type: '',
  });
  useEffect(() => {
    if (appointments.length > 0) {
      let appointmentToPoll = null;
      let cusApp = appointments
        .filter(
          (t) => t.eventType === 'appointment' && t.status === AppointmentStatusTypes.Scheduled,
        )
        .sort(function (a, b) {
          return new Date(a.start) - new Date(b.start);
        });
      if (cusApp.length > 0 && !isComponentMounted && !isFloatingWindow) {
        MainPoller.current = setInterval(() => {
          getUpdatedAvailability();
          // console.log('MAIN POLLER GOING!');
          appointmentToPoll = cusApp[0]; //latest scheduled app
          let start = moment(appointmentToPoll.start);
          let diffMins = moment(start).diff(new Date(), 'minute');

          // Review banner will appear from 15 mins before session till 5mins after scheduled time
          // if member did not join waitroom
          if (diffMins < 15 && diffMins > -5 && !PollForAppStatus.current) {
            //showing banner
            if (
              appointmentToPoll.status == AppointmentStatusTypes.Scheduled ||
              appointmentToPoll.status == AppointmentStatusTypes.MemberInWaitingRoom
            ) {
              prepForBanner(appointmentToPoll, false);
            } else {
              setisSessionAlert(false);
            }
          }

          // this triggers join call banner from 2 mins prior to session
          //till 5 mins afterb scheduled time
          if (diffMins <= 2 && diffMins >= -5 && !PollForAppStatus.current) {
            console.log('DIAGNOSTIC: STARTING APPOINTMENT POLLER AND CANCELLING MAIN');
            startAppointmentPoller(appointmentToPoll);
            setAudioCallData({
              memberId: appointmentToPoll?.patientId,
              appointmentId: appointmentToPoll?.id,
              type: consultationType[appointmentToPoll?.appointmentChannelType],
            });

            clearInterval(MainPoller.current);
          }
        }, MainPollerInterval);
      }
      return () => {
        //decommision the main poller
        clearInterval(MainPoller.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);

  //trigger audio call
  const startAudioCall = (memberId, appointmentId) => {
    dispatch(getMemberProfileDetails(memberId)).then(() => {
      dispatch(actions.startAdhocCall('Call Member', memberId, appointmentId, false));
      setvisibility('hidden');
    });
    completeSession(memberId, appointmentId);
  };
  const completeSession = useCallback((pId, appId) => {
    const baseUrl =
      userType === AUTH_TYPE.COACH
        ? COACH_ROUTES.MEMBER_PROFILE_BASE
        : DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI;
    history.push(`${baseUrl}/${pId}/${appId}`);
  }, []);
  return (
    <>
      {showAlert && (
        <Paper
          style={{
            zIndex: visibility === 'hidden' ? -1 : 10,
            position: 'absolute',
            top: 12,
            right: 32,
          }}
        >
          <Alert severity="info" visibility={visibility} bg="#885FA5">
            <span>
              {isSessionAlert
                ? minLeft >= 1
                  ? `SESSION STARTS IN ${minLeft} ${minLeft > 1 ? 'MINUTES' : 'MINUTE'}`
                  : 'SESSION WILL START SOON'
                : callData.type == SessionChannelTypeId.AS_SCHEDULED_PHONE
                ? 'YOU HAVE AN AUDIO SESSION'
                : memberWaiting && callData.type == SessionChannelTypeId.AS_SCHEDULED_VIDEO
                ? 'MEMBER IN WAITING ROOM'
                : 'SESSION IN PROGRESS'}
            </span>
            {callData.type == SessionChannelTypeId.AS_SCHEDULED_PHONE ? (
              <Button
                style={{ marginLeft: '20px', color: '#fff', fontWeight: 'bold' }}
                onClick={() => startAudioCall(callData.memberId, callData.appointmentId)}
              >
                Call Member
              </Button>
            ) : (
              <ToastButton
                {...{
                  id: appointmentId_,
                  patientDetails: patientDetails,
                  recording: recording,
                  appointments: appointments,
                }}
                patientDetailsLoader={patientDetailsLoader}
                reviewSession={isSessionAlert}
                memberInWaitingRoom={memberWaiting}
                cancelMemberWaiting={() => setmemberWaiting(false)}
                closeAlert={() => setShowAlert(false)}
                hideToast={hideToast}
              />
            )}
          </Alert>
        </Paper>
      )}

      {/* //====== component that shows alert modal when member did not join the waiting room within 6 mins of sesison =====// */}
      {showMissedAlert && <MemberMissedAlert callData={callData} />}

      {errorMsg && (
        <Paper style={{ zIndex: 10, position: 'absolute', top: 12, right: 32 }}>
          <Alert bg="#808080" severity="info">
            <span>{errorMsg}</span>
          </Alert>
        </Paper>
      )}
    </>
  );
};

export default AppNotification;
