import React from 'react'
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Ecgstatus from './Ecgstatus';
import Ecgdevicedata from './Ecgdevicedata';
import EcgHeartratedata from './EcgHeartratedata';
import EkgModal from 'components/EkgModal';

const ThisSessionHearder = ({userStatus,devicedetails ,heartrate, ekgData}) => {
  return (
    <Grid item xs={12}>
         <Box style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', marginBottom: '16px' }}>
          <Typography style={{ marginRight: '24px' , fontSize:"24px" }}>ECG</Typography>
          <Ecgstatus userStatus={userStatus && userStatus?.charAt(0).toUpperCase() + userStatus.slice(1)} />
          <Ecgdevicedata devicedetails={devicedetails} />
          <EcgHeartratedata heartrate={heartrate} />
        </Box> 
        </Box> 
        <EkgModal ecgData={ekgData}/>
    </Grid>
  )
}

export default ThisSessionHearder
