import React from 'react';
import { Chip, styled } from '@mui/material';

const Container = styled('div')({
  '& .MuiChip-root': {
    background: 'white',
    fontFamily: 'Work Sans',
    letterSpacing: '2.5px',
    textTransform: 'uppercase',
    fontSize: '10px',
  },
});

const ChipComponent = ({ label, variant = 'outlined', size = 'md', color = 'primary', style }) => {
  return (
    <Container>
      <Chip label={label} variant={variant} size={size} color={color} style={{ ...style }} />
    </Container>
  );
};

export default ChipComponent;
