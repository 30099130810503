import React from 'react'
import {Grid, Box} from '@mui/material';
import { CustomComponent, PatientchartLable, PatientchartValue } from 'screens/Ecgscreens/Screens';

const PatientChartscreen = ({lable, value}) => {
  return (
    <Grid >
    <Box display={"flex"} gap={"8px"}>
        <CustomComponent text={lable} variant={'body2modified'}/>
        <CustomComponent text={value} variant={'subtitle2value'}/>
    </Box>
</Grid>
  )
}

export default PatientChartscreen



