import { Box, Grid, Paper, Typography, styled } from '@mui/material';
import { lightGreen } from '../../../style/colors';
import { fontFamily } from '../../../style/commonStyles';

export const SearchBarContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
export const InputContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));
export const SearchList = styled('li')(({ theme }) => ({
  '&:hover': {
    backgroundColor: `${lightGreen} !important`,
  },
  fontFamily: `${fontFamily} !important`,
  height: '56px',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
}));
export const SearchResultTray = styled(Typography)(({ theme }) => ({
  maxHeight: '300px',
  overflow: 'auto',
  position: 'absolute',
  width: 'inherit',
  left: '8px',
  zIndex: 4,
}));
export const SearchResultTrayText = styled(Typography)(({ theme }) => ({
  fontFamily,
  paddingLeft: '24px',
  fontSize: '16px',
  lineHeight: '20px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '30%',
  textTransform: 'capitalize',
}));
export const SearchPaper = styled(Paper)(({ theme }) => ({
  maxHeight: '300px',
  overflow: 'auto',
  position: 'absolute',
  width: 'inherit',
  left: '8px',
  zIndex: 4,
}));
