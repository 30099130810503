import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Grid, Typography, styled } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import moment from 'moment';

import classes from './healthDetails.module.css';
import HealthDetailsPCP from './pcp';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import { getMemberProfileHealthDetails } from 'store/actions/memberProfile';
import Loading from 'components/Shared/EcgLoader';
//icons
import Fruit from 'assets/images/icons/profile/fruit.svg';
import Beat from 'assets/images/icons/profile/beat.svg';
import Doctor from 'assets/images/icons/profile/doctor.svg';
import Pill from 'assets/images/icons/profile/pill.svg';
import { getTimezoneName } from 'utilities/Utilities';
import { constants } from './data/constant';

const Root = styled('div')((theme) => ({
  marginTop: '16px',
  '& .MuiSelect-outlined': {
    border: '1px solid rgba(20, 42, 57, 0.16)',
    padding: '10px',
  },
  '& .MuiTypography-body1': {
    fontWeight: '500 !important',
    fontFamily: 'Work Sans !important',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px !important',
  },
  '& .MuiTypography-body2': {
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '10px !important',
    fontWeight: '400 !important',
    fontFamily: 'Work Sans !important',
    fontSize: '14px !important',
  },
  '& .MuiTypography-subtitle1': {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '600',
    fontFamily: 'Work Sans',
    fontSize: '16px',
  },
}));

const HealthDetails = () => {
  const dispatch = useDispatch();
  const { memberId } = useParams();

  const { healthData, loading, error } = useSelector((state) => state.memberProfile);
  const coachId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);

  const [data, setData] = useState([]);

  //timezone related data
  const timezoneValue = getTimezoneName();
  const offsetInMinutes = new Date().getTimezoneOffset();
  useEffect(() => {
    dispatch(
      getMemberProfileHealthDetails(teamId, coachId, memberId, offsetInMinutes, timezoneValue),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  let icons = {
    atrialFibrillationHistory: (() => <Beat />)(),
    medications: (() => <Pill />)(),
    careDelivery: (() => <Doctor />)(),
    lifeStyleDetails: (() => <Fruit />)(),
    medicalHistory: (() => <AddCircleIcon />)(),
  };
  useEffect(() => {
    if (healthData && !loading) {
      const {
        lifestyleDetails,
        atrialFibrillationHistory,
        medicalHistory,
        careDelivery,
        medications,
      } = healthData;
      lifestyleDetails.title = 'lifeStyleDetails';
      atrialFibrillationHistory.title = 'atrialFibrillationHistory';
      medications.title = 'medications';
      medicalHistory.title = 'medicalHistory';
      careDelivery.title = 'careDelivery';
      let d = [
        lifestyleDetails,
        atrialFibrillationHistory,
        medicalHistory,
        careDelivery,
        medications,
      ];
      setData(d);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthData]);

  return (
    <Root>
      {/* All the components are the same as below, will be making this modular and re use it with data */}
      {(loading || error !== null) && <Loading />}
      {data &&
        !loading &&
        data.map((d, index) => (
          <SectionMain
            key={index}
            headerElement={{
              headerText: constants[d?.title],
              helperText: (
                <Typography variant="subtitle1" style={{ marginRight: 25 }}>
                  Last updated:{' '}
                  {d?.updatedDateTime ? moment(d?.updatedDateTime).format('MM-DD-YYYY') : '-'}{' '}
                  {icons[d?.title]}
                </Typography>
              ),
            }}
            bodyElement={[
              {
                childElements: (
                  <Grid container>
                    {Object.entries(d).map((e, index) => {
                      if (constants[e[0]])
                        return (
                          <Grid item xs={4} key={index}>
                            <Typography variant="body2">{constants[e[0]]}</Typography>
                            {typeof e[1] === 'object' ? (
                              e[1].map((m) => (
                                <Typography key={index} variant="body1">
                                  {m}
                                </Typography>
                              ))
                            ) : typeof e[1] === 'string' ? (
                              <Typography variant="body1">{e[1]}</Typography>
                            ) : (
                              <Typography variant="body1">--</Typography>
                            )}

                            {(Object.values(e[1]).length === 0 || !e[1]) && (
                              <Typography variant="body1">--</Typography>
                            )}
                            {/* <Divider className={classes.divider} /> */}
                          </Grid>
                        );
                    })}
                  </Grid>
                ),
              },
            ]}
          />
        ))}
      {data && !loading && <HealthDetailsPCP classes={classes} />}
    </Root>
  );
};

export default HealthDetails;
